import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './sidebar.css'
import { useSelector } from 'react-redux';
import { ViewPath } from '../../../router/router';
import { FilledBooks, FilledChat, FilledGroup, FilledHome, FilledShop, OutlineBooks, OutlineChat, OutlineCompass, OutlineGroup, OutlineHome, OutlineShop } from '../../../assets/const/icon';
import { RootState } from '../../../store';

export default function SideBar() {
    const userInfor = useSelector((state: RootState) => state.account.data)
    const location = useLocation()
    const _modules = [
        {
            id: 'Social',
            path: ViewPath.Default,
            icon: <OutlineHome width='2.4rem' height='2.4rem' />,
            selectedIcon: <FilledHome width='2.4rem' height='2.4rem' />,
        },
        {
            id: 'Center',
            path: ViewPath.Center,
            icon: <OutlineGroup width='2.4rem' height='2.4rem' />,
            selectedIcon: <FilledGroup width='2.4rem' height='2.4rem' />,
        },
        {
            id: "Edu",
            path: ViewPath.Edu,
            icon: <OutlineBooks width='2.4rem' height='2.4rem' />,
            selectedIcon: <FilledBooks width='2.4rem' height='2.4rem' />,
        },
        {
            id: "Ecom",
            path: ViewPath.Ecom,
            icon: <OutlineShop width='2.4rem' height='2.4rem' />,
            selectedIcon: <FilledShop width='2.4rem' height='2.4rem' />,
        },
        {
            id: "Discovery",
            path: ViewPath.Discovery,
            icon: <OutlineCompass width='2.4rem' height='2.4rem' />,
            selectedIcon: <OutlineCompass width='2.4rem' height='2.4rem' />,
        },
        // {
        //     id: 6,
        //     parentId: 1,
        //     name: 'Notification',
        //     link: 'notification',
        //     icon: <OutlineBell width='2.4rem' height='2.4rem' />,
        //     selectedIcon: <FilledBell width='2.4rem' height='2.4rem' />,
        // },
        {
            id: "Chat",
            path: ViewPath.Chat,
            icon: <OutlineChat width='2.4rem' height='2.4rem' />,
            selectedIcon: <FilledChat width='2.4rem' height='2.4rem' />,
        },
    ]
    const [selectedM, setM] = useState(_modules[0])

    useEffect(() => {
        setM(_modules.find((e, i) => i ? location.pathname.startsWith(e.path) : e.path === location.pathname) ?? _modules[0])
    }, [location.pathname])

    return <div className={`col main-layout-sidebar`} >
        {_modules.filter(e => userInfor != undefined || e.id !== 'Center').map((item, index) => {
            const isSelected = selectedM.id === item.id
            return <NavLink key={`sidebar-item-${index}`} to={item.path} className={`sidebar-item ${isSelected ? 'selected' : ''}`}>
                {isSelected ? item.selectedIcon : item.icon}
            </NavLink>
        })}
        {/* {AccountController.token() ? <button type='button' onClick={dialogLogout}><OutlineCircleArrowLeft width={"2.4rem"} height={"2.4rem"} /></button> : null} */}
    </div>
}

import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ExamController } from "../controller"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FilledSendMessage } from "../../../../../assets/const/icon"
import { ExamStatus } from "../da"
import ExamAnswerList from "../../answer/ExamAnswer"
import { DatePickerForm, Select1Form, SelectMultipleForm, TextFieldForm } from "../../../../../project-component/component-form"
import { useForm } from "react-hook-form"
import { TopicController } from "../../../topic/controller"
import { TagController } from "../../../tag/controller"
import { Ultis } from "../../../../../Utils"
import { CalendarType, Text, ToastMessage } from "wini-web-components"

export default function SettingsExam() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const [data, setData] = useState()
    const [activeTab, setActiveTab] = useState(0)

    const renderUI = () => {
        switch (activeTab) {
            case 1:
                return <ExamAnswerList examId={id} />
            default:
                return <CommonTab data={data} />
        }
    }

    const publishExam = () => {
        const updateData = { ...data, status: ExamStatus.test }
        ExamController.edit(updateData).then(res => {
            if (res) {
                setData(updateData)
                ToastMessage.success('Xuất bản bài thi thành công')
            }
        })
    }

    useEffect(() => {
        ExamController.getById(id).then(res => {
            if (res) { setData(res) }
        })
    }, [])

    return <div className="col main-layout-body fillH" style={{ gap: '1.6rem', overflow: 'hidden auto' }}>
        <div className="col" style={{ backgroundColor: '#fff', position: 'sticky', top: 0, padding: '2.4rem 4rem 0', zIndex: 2, gap: '1.6rem' }}>
            <div className='details-view-header row' style={{ width: '100%', justifyContent: 'space-between' }}>
                <div className='col header-breadcum' style={{ gap: '0.4rem' }}>
                    <div className='row' style={{ gap: '0.8rem' }}>
                        <div className='button-text-6'>Danh sách đề thi</div>
                        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '1.2rem' }} />
                        <div className='button-text-6 selected'>Chỉnh sửa đề thi</div>
                    </div>
                    <div><div className='heading-6'>{data?.name}</div></div>
                </div>
                {data?.status === ExamStatus.draft && <button type="button" onClick={publishExam} className={`button-primary row`} style={{ padding: '0.6rem 1.2rem' }}>
                    <FilledSendMessage color={'white'} />
                    <div className='button-text-3'>Xuất bản đề thi</div>
                </button>}
            </div>
            <div className="tab-header-2 row" >
                <div className={`tab-btn label-4 row ${activeTab === 0 ? 'selected' : ''}`} onClick={() => { setActiveTab(0) }}>Thông tin chung</div>
                <div className={`tab-btn label-4 row ${activeTab === 1 ? 'selected' : ''}`} onClick={() => { setActiveTab(1) }}>Nội dung đề</div>
            </div>
        </div>
        {renderUI()}
    </div>
}

const CommonTab = ({ data }) => {
    const methods = useForm({ shouldFocusError: false })
    const [listTopic, setListTopic] = useState([])
    const [listTag, setListTag] = useState([])

    useEffect(() => {
        TopicController.getAll().then(res => {
            if (res) setListTopic(res)
        })
        TagController.getAll().then(res => {
            if (res) setListTag(res)
        })
    }, [])

    const onChangeExamData = () => {
        let updateData = methods.getValues()
        if(updateData.time && typeof updateData.time === "string") updateData.time = parseInt(updateData.time)
        if (updateData.dateStart) {
            updateData.dateStart = Ultis.stringToDate(updateData.dateStart, 'dd/mm/yyyy hh:mm').getTime()
        }
        ExamController.edit(updateData).then(res => {
            if (res) {
                ToastMessage.success('Chỉnh sửa bài thi thành công')
            }
        })
    }

    useEffect(() => {
        if (data) {
            Object.keys(data).forEach(props => {
                if (data[props]) {
                    if (props === 'dateStart') {
                        methods.setValue(props, Ultis.datetoString(new Date(data[props]), 'dd/mm/yyyy hh:mm'))
                    } else {
                        methods.setValue(props, data[props])
                    }
                }
            })
        }
    }, [data])

    return <form className="col" style={{ padding: '1.6rem 4rem' }}>
        <TextFieldForm
            className={'row'}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            label={<Text className="label-3" style={{ width: '18rem' }}>Tên đề</Text>}
            placeholder="Tên đề"
            name={'name'}
            register={methods.register}
            onBlur={onChangeExamData}
        />
        <div className="col divider" />
        <Select1Form
            className={'row'}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            control={methods.control}
            label={<Text className="label-3" style={{ width: '18rem' }}>Loại bài thi</Text>}
            placeholder={'Chọn loại bài thi'}
            name={'status'}
            value={methods.watch('status')}
            options={[{ id: ExamStatus.test, name: 'Thi thử' }, { id: ExamStatus.real, name: 'Thi cấp chứng chỉ/bằng' }]}
            onChange={onChangeExamData}
        />
        <div className="col divider" />
        {methods.watch('status') === ExamStatus.real && <DatePickerForm
            className={'row'}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            control={methods.control}
            label={<Text className="label-3" style={{ width: '18rem' }}>Ngày thi</Text>}
            placeholder={'Chọn ngày thi'}
            name={'dateStart'}
            pickerType={CalendarType.DATETIME}
            onChange={(ev) => {
                onChangeExamData()
            }}
        />}
        <div className="col divider" />
        <TextFieldForm
            className={'row'}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            label={<Text className="label-3" style={{ width: '18rem' }}>Thời gian thi (Phút)</Text>}
            placeholder="Nhập số phút"
            name={'time'}
            register={methods.register}
            type={'number'}
            onBlur={onChangeExamData}
        />
        <div className="col divider" />
        <TextFieldForm
            className={'row'}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            label={<Text className="label-3" style={{ width: '18rem' }}>Số điểm đạt</Text>}
            name={'quantity'}
            placeholder={'Số câu trả lời đúng'}
            register={methods.register}
            type={'number'}
            onBlur={onChangeExamData}
        />
        <div className="col divider" />
        <Select1Form
            className={'row'}
            control={methods.control}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            label={<Text className="label-3" style={{ width: '18rem' }}>Phân loại chủ đề</Text>}
            placeholder="Chọn phân loại chủ đề"
            name={'topicId'}
            value={methods.watch('topicId')}
            options={listTopic}
            onChange={onChangeExamData}
        />
        <div className="col divider" />
        <SelectMultipleForm
            className={'row'}
            control={methods.control}
            style={{ gap: '8.8rem', maxWidth: '106.8rem' }}
            label={<Text className="label-3" style={{ width: '18rem' }}>Tag chủ đề (Tối đa 5)</Text>}
            placeholder={'Chọn chủ đề'}
            name={'tags'}
            value={methods.watch('tags')}
            options={listTag}
            onChange={onChangeExamData}
        />
    </form>
}
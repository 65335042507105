import { forwardRef, useEffect, useRef, useState } from "react"
import { Popup, Text, TextField, ToastMessage, closePopup, showPopup } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faEdit } from "@fortawesome/free-solid-svg-icons"
import { useForm } from "react-hook-form"
import { CenterController } from "../controller"
import { useNavigate } from "react-router-dom"
import { CenterPermisson } from "../da"
import ListNews from "../../social/home/local-component/list-news"
import { ViewPath } from "../../../../router/router"

export default function CommonTab({ centerItem, userInfor, permisson }) {
    const navigate = useNavigate()
    const [centerData, setCenterData] = useState()
    const ref = useRef()

    const showPopupEditDescription = () => {
        showPopup({
            ref: ref,
            style: { width: '80%', maxWidth: '68rem', backgroundColor: '#fff' },
            heading: <div className="heading-6 popup-header" style={{ textAlign: 'center' }}>Viết mô tả</div>,
            content: <PopupEditDescription description={centerData.description} ref={ref} onSubmit={(vl) => {
                const newCenterData = { ...centerData, description: vl }
                CenterController.edit(newCenterData).then(res => {
                    if (res) {
                        ToastMessage.success('Cập nhật mô tả thành công')
                        setCenterData(newCenterData)
                    }
                })
            }} />
        })
    }

    useEffect(() => {
        if (centerItem) {
            setCenterData(centerItem)
        }
    }, [centerItem])

    return centerData ? <div className="col" style={{ padding: '4rem 0' }}>
        <Popup ref={ref} />
        <div className='col' style={{ gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <Text className='heading-4'>Giới thiệu tổng quan</Text>
                {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button type="button" onClick={showPopupEditDescription} className="row icon-button28"><FontAwesomeIcon icon={faEdit} /></button> : undefined}
            </div>
            <Text className='subtitle-3'>Số điện thoại: {centerData?.phone ?? '-'}</Text>
            <Text maxLine={2} className='subtitle-3'>Địa chỉ: {centerData?.address ?? '-'}</Text>
            {centerData.description?.length ? <Text className="body-2" showMore style={{ width: '100%', marginTop: '1.6rem' }}>{centerData.description}</Text> : undefined}
        </div>
        <div className="col divider" style={{ margin: '4rem 0 3.2rem 0' }} />
        <div className="col" style={{ gap: '3.2rem' }}>
            {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button type="button" onClick={() => { navigate(ViewPath.NewsForm + `?centerid=${centerItem.id}`) }} className='row' style={{ backgroundColor: '#fff', borderRadius: '1.2rem', width: 'calc(100% - 4.8rem)', margin: '0 2.4rem', gap: '1.6rem', padding: '1.6rem 2.4rem' }}>
                <img src={userInfor?.avatarUrl} alt="" style={{ width: '4.4rem', height: '4.4rem', borderRadius: '50%' }} />
                <TextField
                    className="regular3"
                    style={{ flex: 1, border: 'none', borderRadius: '2.4rem', height: '4.8rem', padding: '0 1.6rem', textAlign: 'start' }}
                    placeholder="Đăng bài viết mới..."
                    disabled
                />
                <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '2.4rem', color: '#667994' }} />
            </button> : undefined}
            <ListNews centerId={centerItem.id} />
        </div>
    </div> : <div/>
}

const PopupEditDescription = forwardRef(function PopupEditDescription(data, ref) {
    const methods = useForm({ shouldFocusError: false })

    useEffect(() => {
        methods.setValue('description', data.description ?? '')
    }, [])

    return <div className="col">
        <textarea
            {...methods.register('description')}
            placeholder="Mô tả về trung tâm của bạn..."
            className="regular"
            style={{ minHeight: '24rem', outline: 'none', border: 'none', resize: 'none', padding: '1.6rem' }}
        />
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Hủy</Text>
            <div style={{ flex: 1 }} />
            <button
                type="button"
                className={`row ${methods.watch('description') ? 'button-primary' : 'button-disabled'}`}
                onClick={() => {
                    data.onSubmit(methods.getValues('description'))
                    closePopup(ref)
                }}
            >
                <Text className="button-text-3">Xác nhận</Text>
            </button>
        </div>
    </div>
})
import { Text } from "wini-web-components";




export default function Certificate({ centerItem, permisson }){

    return(
        <div className="col main-layout-body" style={{margin:"2.4rem 0rem",width:"100%"}}>
            <div className="col" style={{gap:'2rem'}}>
                <div className="col" style={{borderRadius:'2rem',width:"100%"}}>
                    <img src="https://www.elle.vn/wp-content/uploads/2018/07/14/elle-viet-nam-dia-diem-lan-bien-2.jpg" style={{width:"100%"}}></img>
                </div>
                <div className="row" >
                    <Text className="comp-text heading-4">Chứng chỉ thuyền trưởng hạng 1</Text>
                </div>
                <div className="row" style={{gap:'1.6rem'}}>
                    <Text className="comp-text label-1" style={{color:"rgba(0, 32, 77, 0.6)"}}>Loại Chứng Chỉ</Text>
                    <div className="row" style={{gap:'1rem'}}>
                        <div className="row tag-infor" style={{borderRadius:'100px'}}>
                            <Text className="comp-text button-text-6">Tàu thủy</Text>
                        </div>
                        <div className="row tag-infor" style={{borderRadius:'100px'}}>
                            <Text className="comp-text button-text-6">Tàu Lái trưởng</Text>
                        </div>
                    </div>
                </div>
                <div className="col" style={{gap:'1.5rem'}}>
                    <Text className="comp-tex body-2 " style={{color:"rgba(0, 32, 77, 0.80)"}}>Căn cứ,Thông tư số 39/2019/TT-BGTVT ngày 15/10/2019 của Bộ Giao thông vận tải quy định trách nhiệm của chủ phương tiện, thuyền viên, 
                        người lái phương tiện, đảm nhiệm chức danh thuyền viên và định biên an toàn tối thiểu trên phương tiện thủy nội địa.</Text>
                    <Text className="comp-text body-2" style={{color:"rgba(0, 32, 77, 0.80)"}}>Với chứng chỉ này, học viên có thể vận hành và điều khiển:</Text>
                    <div className="col" style={{padding:"2.2rem",backgroundColor:"#F9FAFB",borderRadius:"1rem",border:"1px solid rgba(0, 53, 128, 0.08)"}}>
                        <ul className="col" style={{listStyleType:'disc'}}>
                            <li className="comp-text body-2" style={{color:"rgba(0, 32, 77, 0.80)"}}>- Phương tiện có tổng công suất máy trong trên 1.000 mã lực hoặc máy ngoài trên 3000 mã lực.</li>
                            <li className="comp-text body-2" style={{color:"rgba(0, 32, 77, 0.80)"}}>- Phương tiện chở hàng có trọng tải toàn phần trên 1.000 tấn.</li>
                            <li className="comp-text body-2" style={{color:"rgba(0, 32, 77, 0.80)"}}>- Phương tiện chở khách trên 100 người.</li>
                            <li className="comp-text body-2" style={{color:"rgba(0, 32, 77, 0.80)"}}>- Đoàn lai có trọng tải toàn phần trên 1.500 tấn.</li>
                            <li className="comp-text body-2" style={{color:"rgba(0, 32, 77, 0.80)"}}>- Phà có trọng tải toàn phần trên 350 tấn và trên 100 người.</li>
                        </ul>
                    </div>
                  </div>
            </div>
        </div>
    )
}
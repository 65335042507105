import { forwardRef, useEffect, useRef, useState } from "react"
import { CenterController } from "../controller"
import { CustomerController } from "../../customer/controller"
import { Popup, Select1, Text, TextField, ToastMessage, closePopup, showPopup } from "wini-web-components"
import { CenterPermisson } from "../da"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH, faPlus, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FilledSetupPreferences, OutlineChat } from "../../../../assets/const/icon"
import { AccountActions } from "../../account/reducer"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { validateForm, ValidateType } from "../../../../project-component/validate"

export default function ListMember({ centerItem, permisson, onDelete, reloadMember }) {
    const userInfor = useSelector((state) => state.account.data)
    const [owner, setOwner] = useState()
    const [admins, setAdmins] = useState([])
    const [members, setMembers] = useState({ totalCount: undefined, data: [] })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()

    const getMembers = async (page) => {
        const res = await CenterController.getListSimpleMember({ page: page ?? Math.floor(members.data.length / 10) + 1, take: 10, filter: [{ field: 'centerId', operator: '=', value: centerItem.id }, { field: 'permisson', operator: '=', value: CenterPermisson.member }] })
        if (res) {
            const customerIds = res.data.map(e => e.customerId).filter(id => members.data.every(mem => mem.id !== id))
            if (customerIds.length) {
                const customerItems = await CustomerController.getByIds(customerIds)
                if (!customerItems) return
                setMembers({
                    totalCount: res.totalCount,
                    data: [...members.data, ...customerItems]
                })
            }
        }
    }

    const showPopupAddMember = () => {
        showPopup({
            ref: ref,
            style: { width: '80%', maxWidth: '60rem', backgroundColor: '#fff' },
            heading: <div className="heading-6 popup-header" style={{ textAlign: 'center' }}>Thêm thành viên</div>,
            content: <PopupAddMember
                ref={ref}
                centerId={centerItem.id}
                permisson={permisson}
                onClose={async (newCustomerIds) => {
                    if (newCustomerIds?.length) {
                        const customerItems = await CustomerController.getByIds(newCustomerIds)
                        if (!customerItems) return
                        setMembers({
                            totalCount: members.totalCount + newCustomerIds.length,
                            data: [...customerItems, ...members.data.slice(0, members.data.length - newCustomerIds.length + 1)]
                        })
                    }
                }}
            />
        })
    }


    const showActionOptions = (ev, per, mem) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { left: `${ev.pageX}px`, top: `${ev.pageY}px` },
            content: <div className="col more-action-popup">
                {permisson === CenterPermisson.owner ? <button type="button" className="row" onClick={async () => {
                    const res = await CenterController.editMember(
                        [
                            ...mem.customerCenters.filter(e => {
                                const _check = e.centerId === centerItem.id
                                if (_check) e.permisson = CenterPermisson.owner
                                return _check
                            }),
                            ...userInfor.customerCenters.filter(e => e.centerId === centerItem.id).map(e => {
                                return {
                                    ...e,
                                    permisson: CenterPermisson.admin
                                }
                            })
                        ]
                    )
                    if (res) {
                        ToastMessage.success('Chuyển quyền sở hữu trung tâm thành công')
                        switch (per) {
                            case CenterPermisson.admin:
                                setAdmins(admins.filter(e => e.id !== mem.id))
                                break;
                            default:
                                setMembers({
                                    totalCount: members.totalCount - 1,
                                    data: members.data.filter(e => e.id !== mem.id)
                                })
                                break;
                        }
                        AccountActions.getInfor(dispatch)
                    }
                    closePopup(ref)
                }}>
                    <Text className="label-4">Chuyển quyền sở hữu trung tâm</Text>
                </button> : undefined}
                {per === CenterPermisson.admin ? <button type="button" className="row" onClick={async () => {
                    const res = await CenterController.editMember(mem.customerCenters.filter(e => {
                        const _check = e.centerId === centerItem.id
                        if (_check) e.permisson = CenterPermisson.member
                        return _check
                    }))
                    if (res) {
                        ToastMessage.success('Bỏ quyền quản trị thành công')
                        setAdmins(admins.filter(e => e.id !== mem.id))
                        getMembers(1)
                    }
                    closePopup(ref)
                }}>
                    <Text className="label-4">Bỏ quyền quản trị</Text>
                </button> : undefined}
                {per === CenterPermisson.member ? <button type="button" className="row" onClick={async () => {
                    const res = await CenterController.editMember(mem.customerCenters.filter(e => {
                        const _check = e.centerId === centerItem.id
                        if (_check) e.permisson = CenterPermisson.admin
                        return _check
                    }))
                    if (res) {
                        ToastMessage.success('Thành viên ' + mem.name ?? mem.email + ' được cấp quyền quản trị thành công')
                        setMembers({
                            totalCount: members.totalCount - 1,
                            data: members.data.filter(e => e.id !== mem.id)
                        })
                        setAdmins([...admins, mem])
                    }
                    closePopup(ref)
                }}>
                    <Text className="label-4">Cấp quyền quản trị</Text>
                </button> : undefined}
                <button type="button" className="row" onClick={async () => {
                    const res = await CenterController.deleteMember(mem.customerCenters.filter(e => e.centerId === centerItem.id).map(e => e.id))
                    if (res) {
                        if (mem.id === userInfor.id) {
                            ToastMessage.success('Rời trung tâm thành công')
                            AccountActions.getInfor(dispatch)
                            navigate('/centers')
                            return
                        }
                        switch (per) {
                            case CenterPermisson.admin:
                                setAdmins(admins.filter(e => e.id !== mem.id))
                                break;
                            default:
                                setMembers({
                                    totalCount: members.totalCount - 1,
                                    data: members.data.filter(e => e.id !== mem.id)
                                })
                                break;
                        }
                        onDelete()
                        ToastMessage.success('Thành viên ' + mem.name ?? mem.email + ' đã bị xóa khỏi trung tâm')
                    }
                    closePopup(ref)
                }}>
                    <Text className="label-4">{mem.id === userInfor.id ? 'Rời trung tâm' : 'Xóa thành viên'}</Text>
                </button>
            </div>
        })
    }

    const memberTile = (mem, per = CenterPermisson.member) => {
        return <div key={mem?.id} className="row" style={{ gap: '0.8rem', padding: '1.6rem 0.2rem' }}>
            <img src={mem?.avatarUrl} alt="" style={{ borderRadius: '50%', width: '4.8rem', height: '4.8rem' }} />
            <div className='col' style={{ gap: '0.4rem', flex: 1 }}>
                <div className="row" style={{ gap: '0.8rem' }}>
                    <Text className='title-3'>{mem?.name ?? mem?.userName}</Text>
                    <div className="row tag-infor">
                        <Text>{per === CenterPermisson.owner ? 'Chủ trung tâm' : per === CenterPermisson.admin ? 'Quản trị viên' : 'Thành viên'}</Text>
                    </div>
                </div>
                <Text className='subtitle-3'>{mem?.email}</Text>
            </div>
            <button type="button" className="row icon-button40" style={{ backgroundColor: 'var(--background)', borderRadius: '0.8rem' }}>
                <OutlineChat width="2rem" height="2rem" />
            </button>
            {
                permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button onClick={(ev) => {
                    showActionOptions(ev, per, mem)
                }} type="button" className="row icon-button40" style={{ backgroundColor: 'var(--background)', borderRadius: '0.8rem', visibility: per === CenterPermisson.owner ? 'hidden' : 'visible' }}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                </button> : undefined
            }
        </div>
    }

    useEffect(() => {
        if (permisson === CenterPermisson.owner) {
            if (userInfor) setOwner(userInfor)
        } else {
            CenterController.getListSimpleMember({ page: 1, take: 1, filter: [{ field: 'centerId', operator: '=', value: centerItem.id }, { field: 'permisson', operator: '=', value: CenterPermisson.owner }] }).then(async (res) => {
                if (res) {
                    const _owner = await CustomerController.getById(res.data[0].customerId)
                    if (_owner) setOwner(_owner)
                }
            })
        }
    }, [permisson, userInfor])

    useEffect(() => {
        if (centerItem) {
            CenterController.getListSimpleMember({ page: 1, take: 50, filter: [{ field: 'centerId', operator: '=', value: centerItem.id }, { field: 'permisson', operator: '=', value: CenterPermisson.admin }] }).then(async (res) => {
                if (res) {
                    const adminIds = res.data.map(e => e.customerId)
                    if (adminIds.length) {
                        const customerItems = await CustomerController.getByIds(adminIds)
                        if (!customerItems) return
                        setAdmins(customerItems)
                    }
                }
            })
            getMembers(1)
        }
    }, [reloadMember])

    return <>
        <Popup ref={ref} />
        <div className='col' style={{ margin: '2.4rem 0', width: '100%', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '1.6rem' }}>
                <TextField
                    style={{ backgroundColor: 'var(--background)', height: '4rem', flex: 1, maxWidth: '40rem' }}
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#667994', marginTop: '0.1rem' }} />}
                    placeholder="Tìm khóa học"
                />
                <button type="button" className="row" style={{ gap: '0.8rem' }}>
                    <FilledSetupPreferences width="2.4rem" height="2.4rem" />
                    <Text className="button-text-2" style={{ color: '#00204D99' }}>Bộ lọc</Text>
                </button>
                <div style={{ flex: 1 }} />
                {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button type='button' onClick={showPopupAddMember} className='row button-primary' style={{ borderRadius: '0.8rem' }}>
                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.4rem' }} />
                    <Text className='button-text-3'>Mời</Text>
                </button> : null}
            </div>
            <div className="col" style={{ padding: '2rem 0', borderBottom: 'var(--border-grey1)' }}>
                <Text className="heading-7">Quản trị viên</Text>
                {owner ? memberTile(owner, CenterPermisson.owner) : undefined}
                {admins.map(mem => { return memberTile(mem, CenterPermisson.admin) })}
            </div>
            <div className="col" style={{ padding: '2rem 0', borderBottom: 'var(--border-grey1)' }}>
                <Text className="heading-7">Thành viên</Text>
                {members.data.map(mem => { return memberTile(mem, CenterPermisson.member) })}
            </div>
        </div>

    </>
}


const PopupAddMember = forwardRef(function PopupAddMember(data, ref) {
    const [emails, setEmails] = useState([])
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const methods = useForm({ shouldFocusError: false, defaultValues: { mail: '' } })

    const checkValidEmail = (value) => {
        const checkEmail = validateForm({ list: [{ Name: 'mail', Validate: [{ type: ValidateType.email }] }], formdata: { mail: value.trim() } })
        return !checkEmail
    }

    const searchCustomer = async (value) => {
        const _mail = value.trim()
        if (_mail.length) {
            if (emails.some(e => e.email === _mail)) {
                methods.setValue('mail', '')
                return
            }
            if (valid) {
                const res = await CustomerController.getIDByEmail(_mail)
                if (res) {
                    const ids = res.split(",")
                    CustomerController.getByIds(ids).then(cusRes => {
                        if (cusRes) setEmails([...emails, ...cusRes])
                    })
                }
            } else {
                setEmails([...emails, { email: _mail, invalid: true }])
            }
            methods.setValue('mail', '')
        } else {
            setValid(false)
        }
    }

    const submitInvite = async () => {
        setLoading(true)
        const _mail = methods.getValues('mail').trim()
        const newCustomerCenters = []
        if (valid && _mail.length && emails.every(e => e.email !== _mail)) {
            const id = await CustomerController.getIDByEmail(_mail)
            if (id?.length) {
                const _newMember = await CustomerController.getById(id)
                if (_newMember.customerCenters?.some(el => el.centerId === data.centerId)) {
                    setLoading(false)
                    ToastMessage.errors(_mail + ' đã là thành viên trong trung tâm')
                    return
                }
                newCustomerCenters.push({
                    id: window.crypto.randomUUID(),
                    name: _newMember.name ?? 'member',
                    dateCreated: (new Date()).getTime(),
                    customerId: _newMember.id,
                    centerId: data.centerId,
                    permisson: CenterPermisson.member,
                })
            }
        }
        newCustomerCenters.push(...emails.filter(e => !e.invalid && (!e.customerCenters?.length || e.customerCenters.every(el => el.centerId !== data.centerId))).map(e => {
            return {
                id: window.crypto.randomUUID(),
                name: e.name ?? 'member',
                dateCreated: (new Date()).getTime(),
                customerId: e.id,
                centerId: data.centerId,
                permisson: e.permisson ?? CenterPermisson.member,
            }
        }))
        if (!newCustomerCenters.length) {
            setLoading(false)
            ToastMessage.errors('Tài khoản đã là thành viên trong trung tâm')
            return
        }
        const res = await CenterController.addMember(newCustomerCenters)
        if (res) {
            ToastMessage.success('Thêm thành viên mới thành công')
            data.onClose(newCustomerCenters.map(e => e.customerId))
            closePopup(ref)
        } else {
            setLoading(false)
        }
    }

    return <div className="col">
        <div className='row' style={{ gap: '1.6rem', flex: 1, padding: '0.8rem' }}>
            <div className='row input-invite-member'>
                {
                    emails.map((e, i) => {
                        return <div key={e.id ?? e.mail + '-' + i} className={`row ${e.invalid ? 'tag-error' : 'button-grey'}`} style={{ padding: '0.4rem', borderRadius: '0.2rem' }}>
                            <Text className='button-text-3'>{e.email}</Text>
                            <button type='button' onClick={() => {
                                setEmails(emails.filter(el => el.email !== e.email))
                            }} style={{ padding: '0.4rem' }}>
                                <FontAwesomeIcon icon={faXmark} style={{ fontSize: '1.4rem' }} />
                            </button>
                        </div>
                    })
                }
                <input
                    style={{ flex: 1, minWidth: '12rem' }}
                    className='regular2'
                    placeholder='Nhập email'
                    onKeyDown={(ev) => {
                        if (ev.key.toLowerCase() === 'enter') searchCustomer(ev.target.value)
                    }}
                    name='mail'
                    {...methods.register('mail', {
                        onChange: (ev) => {
                            const _tmp = checkValidEmail(ev.target.value)
                            if (valid !== _tmp) setValid(_tmp)
                        },
                    })}
                />
            </div>
            <button type='button' onClick={submitInvite} className={`row ${((emails.length && emails.every(e => !e.invalid)) || valid) && !loading ? 'button-primary' : 'button-disabled'}`} style={{ borderRadius: '0.4rem', padding: '0.8rem 1.6rem' }}>
                <Text className='button-text-3'>Mời</Text>
            </button>
        </div>
        <div className='col' style={{ padding: '1.6rem' }}>
            {
                emails.filter(e => !e.invalid).map(e => {
                    return <div key={e.id} className='row' style={{ gap: '0.8rem', height: '6rem', borderBottom: 'var(--border-grey1  )' }}>
                        <img src={e.avatarUrl} alt='' style={{ borderRadius: '50%', width: '4rem', height: '4rem' }} />
                        <div className='col' style={{ gap: '0.4rem', flex: 1 }}>
                            <Text className='title-3'>{e.name ?? e.userName}</Text>
                            <Text className='subtitle-3'>{e.email}</Text>
                        </div>
                        {
                            e.customerCenters?.some(el => el.centerId === data.centerId) ?
                                <Text className='semibold2'>Đã là thành viên</Text> :
                                <Select1
                                    className='regular2'
                                    style={{ border: 'none', width: '12rem' }}
                                    hideSearch
                                    disabled={data.permisson == undefined || data.permisson === CenterPermisson.member}
                                    options={[{ id: CenterPermisson.admin, name: 'Quản trị viên' }, { id: CenterPermisson.member, name: 'Thành viên' }]}
                                    value={e.permisson ?? CenterPermisson.member}
                                    onChange={(vl) => {
                                        setEmails(emails.map(el => {
                                            if (el.id === e.id) el.permisson = vl.id
                                            return el
                                        }))
                                    }}
                                />
                        }
                    </div>
                })
            }
        </div>
    </div>
})
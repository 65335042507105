import { useSelector } from "react-redux"
import { ClassController } from "../../class/controller"
import { CellAlignItems, Checkbox, closePopup, DatePicker, Dialog, Popup, Select1, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField } from "wini-web-components"
import { forwardRef, useEffect, useRef, useState } from "react"
import { Ultis } from "../../../../../Utils"
import { StudentController } from "../../student/controller"
import { faCaretDown, faCaretRight, faEllipsisH, faFile, faFileExport, faGift, faRightToBracket, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FilledSetupPreferences } from "../../../../../assets/const/icon"
import { SessionController } from "../../session/controller"
import { useForm } from "react-hook-form"
import { CKEditorForm, TextFieldForm } from "../../../../../project-component/component-form"

export default function TeachingClassesTab({ courseId }) {
    const userInfor = useSelector((state) => state.account.data)
    const dialogRef = useRef()
    const ref = useRef()
    const [list, setList] = useState([])

    const showListStudent = (item) => {
        showPopup({
            ref: ref,
            heading: <div className="popup-header heading-7">Tiến trình học lớp {item.name}</div>,
            style: { height: '80%', width: '68%' },
            content: <PopupSession ref={ref} classItem={item} />
        })
    }

    const showReportStudent = (item) => {
        showPopup({
            ref: ref,
            heading: <div className="popup-header heading-7">Học sinh lớp {item.name}</div>,
            style: { height: '80%', width: '50%' },
            content: <PopupStudentReport ref={ref} classItem={item} />
        })
    }

    useEffect(() => {
        if (userInfor) {
            ClassController.getListSimpleAuth({ page: 1, take: 100, filter: [{ field: 'courseId', operator: '=', value: courseId }, { field: 'customerId', operator: '=', value: userInfor.id }] }).then(res => {
                if (res) setList(res.data.map(e => {
                    if (e.content) {
                        try {
                            var schedule = JSON.parse(e.content)
                        } catch (error) {
                            console.log(error)
                        }
                        e.schedule = schedule
                    }
                    return e
                }))
            })
        }
    }, [userInfor, courseId])

    return <div className="row" style={{ gap: '2.4rem', flexWrap: 'wrap', alignItems: 'stretch' }}>
        <Dialog ref={dialogRef} />
        <Popup ref={ref} />
        {list.map((item) => {
            return <div key={item.id} className="col class-infor-container col12 col24-min">
                <div className="row" style={{ gap: '0.8rem', alignItems: 'start' }}>
                    <Text className="heading-7" style={{ flex: 1, width: '100%', lineHeight: 'normal', marginTop: '0.4rem' }} maxLine={2}>{item.name}</Text>
                    <button className="row icon-button28" onClick={() => { showListStudent(item) }}><FontAwesomeIcon icon={faRightToBracket} /></button>
                    <button className="row icon-button28" onClick={() => { showReportStudent(item) }} ><FontAwesomeIcon icon={faFileExport} /></button>
                </div>
                <div className="row" style={{ alignItems: 'start', gap: '2.4rem' }}>
                    <div className="col" style={{ flex: 1, gap: '1.6rem' }}>
                        <div className="col">
                            <Text className="label-5">Khai giảng</Text>
                            <Text className="heading-8" maxLine={1} style={{ width: '100%' }}>{item.startDate ? Ultis.datetoString(new Date(item.startDate)) : "-"}</Text>
                        </div>
                        <div className="col">
                            <Text className="label-5">Ngày kết thúc</Text>
                            <Text className="heading-8" maxLine={1} style={{ width: '100%' }}>{item.endDate ? Ultis.datetoString(new Date(item.endDate)) : "-"}</Text>
                        </div>
                    </div>
                    <div className="col" style={{ flex: 2, gap: '1.6rem' }}>
                        <div className="col">
                            <Text className="label-5">Học phí</Text>
                            <Text className="heading-8" maxLine={1} style={{ width: '100%' }}>{item.price ? Ultis.money(item.price) : "-"}đ</Text>
                        </div>
                        <div className="col">
                            <Text className="label-5">Lịch học</Text>
                            <ul>
                                {(item.schedule ?? []).map(e => {
                                    let startTime = new Date(e.time)
                                    switch (startTime.getDay()) {
                                        case 0:
                                            var dayWeekTitle = 'Chủ nhật'
                                            break;
                                        case 1:
                                            dayWeekTitle = 'Thứ 2'
                                            break;
                                        case 2:
                                            dayWeekTitle = 'Thứ 3'
                                            break;
                                        case 3:
                                            dayWeekTitle = 'Thứ 4'
                                            break;
                                        case 4:
                                            dayWeekTitle = 'Thứ 5'
                                            break;
                                        case 5:
                                            dayWeekTitle = 'Thứ 6'
                                            break;
                                        case 6:
                                            dayWeekTitle = 'Thứ 7'
                                            break;
                                        default:
                                            break;
                                    }
                                    let endTime = new Date(e.time)
                                    endTime.setMinutes(endTime.getMinutes() + e.duration)
                                    return <li key={e.time} className="heading-8" >
                                        {`${dayWeekTitle} ${startTime.getHours() > 9 ? startTime.getHours() : `0${startTime.getHours()}`}:${startTime.getMinutes() > 9 ? startTime.getMinutes() : `0${startTime.getMinutes()}`}-${endTime.getHours() > 9 ? endTime.getHours() : `0${endTime.getHours()}`}:${endTime.getMinutes() > 9 ? endTime.getMinutes() : `0${endTime.getMinutes()}`}`}
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </div >
}

const PopupSession = forwardRef(function PopupSession(data, ref) {
    const [students, setStudents] = useState([])
    const [search, setSearch] = useState()
    const [session, setSession] = useState([])
    const _studentController = new StudentController("student")
    const _today = new Date()
    const _startDate = new Date(data.classItem.startDate)
    const _endDate = new Date(data.classItem.endDate)
    const _studentIds = data.classItem.studentId?.split(",")
    const [date, setDate] = useState(_today.getTime() < _startDate.getTime() ? _startDate : _today.getTime() > _endDate.getTime() ? _endDate : _today)
    const dialogRef = useRef()
    const popupRef = useRef()

    const updateReportSession = (item) => {
        item.id ??= window.crypto.randomUUID().replaceAll("-", "")
        item.classId ??= data.classItem.id.toLowerCase().replaceAll("-", "")
        if (!item.time) {
            const _time = (new Date(date.getFullYear(), date.getMonth(), date.getDate())).getTime()
            item.time = _time
        }
        SessionController.edit(item)
        setSession([...session.filter(e => e.studentId !== item.studentId), item])
    }

    useEffect(() => {
        if (_studentIds?.length) {
            _studentController.getByListId(_studentIds).then(res => {
                if (res) setStudents(res)
            })
        }
    }, [data.classItem])

    useEffect(() => {
        if (_studentIds?.length) {
            const _checkSchedule = (data.classItem.schedule ?? []).some((e) => {
                const _eTime = new Date(e.time)
                return _eTime.getDay() === date.getDay()
            })
            if (_checkSchedule) {
                const _time = (new Date(date.getFullYear(), date.getMonth(), date.getDate())).getTime()
                SessionController.getListSimple({ page: 1, size: _studentIds.length, searchRaw: `@time:[${_time} ${_time}] @classId:{${data.classItem.id.toLowerCase().replaceAll("-", "")}}` }).then(res => {
                    if (res) setSession(res.data?.length ? res.data : _studentIds.map(id => {
                        return {
                            studentId: id,
                            time: _time,
                            classId: data.classItem.id.toLowerCase().replaceAll("-", "")
                        }
                    }))
                })
            } else {
                setSession([])
            }
        }
    }, [date])

    return <div className="col" style={{ flex: 1, gap: '1.6rem', padding: '2.4rem' }}>
        <Dialog ref={dialogRef} />
        <Popup ref={popupRef} />
        <div className="row">
            <TextField
                style={{ maxWidth: '32rem' }}
                placeholder="Tìm kiếm "
                prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#00204D99' }} />}
                onChange={(ev) => {
                    if (ev.target.value.trim().length) {
                        setSearch(students.filter(e => e.name.toLowerCase().includes(ev.target.value.trim().toLowerCase())))
                    } else setSearch(undefined)
                }}
            />
            <div className="row divider" style={{ height: '2.4rem' }} />
            <button type="button" className="row button-grey" style={{ gap: '0.8rem', cursor: 'pointer' }}>
                <FilledSetupPreferences />
                <Text className="button-text-3" style={{ color: '#00204D99' }}>Bộ lọc</Text>
            </button>
            <div style={{ flex: 1, padding: '0 0.4rem' }} />
            <DatePicker
                placeholder='Chọn ngày'
                min={_startDate}
                max={_endDate}
                value={Ultis.datetoString(date)}
                onChange={(v) => { setDate(Ultis.stringToDate(v)) }}
            />
        </div>
        <div style={{ flex: 1, width: '100%', height: '100%', overflow: 'auto' }}>
            {_studentIds?.length ? session.length ? <Table>
                <TbHeader>
                    {
                        [
                            <TbCell key={'h0'} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                    <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                                    <Text className='heading-9'>STT</Text>
                                </div>
                            </TbCell>,
                            <TbCell key={'h1'} style={{ minWidth: '12rem' }} align={CellAlignItems.start}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Tên</Text>
                            </TbCell>,
                            <TbCell key={'h2'} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Tuổi</Text>
                            </TbCell>,
                            <TbCell key={'h3'} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Giới tính</Text>
                            </TbCell>,
                            <TbCell key={'h4'} style={{ minWidth: '45rem' }} align={CellAlignItems.center}>
                                <div className="col" style={{ gap: '0.8rem', padding: '0.8rem 0 0.4rem' }}>
                                    <Text className='heading-9' style={{ textAlign: CellAlignItems.center, width: '100%' }}>Điểm danh</Text>
                                    <div className="row" style={{ borderTop: 'var(--border-grey1)', gap: '0.4rem', alignItems: 'stretch', padding: '0.8rem 0 0.4rem' }}>
                                        <Text className='regular-1' style={{ textAlign: CellAlignItems.center, flex: 1 }}>Có mặt</Text>
                                        <div style={{ borderLeft: 'var(--border-grey1)' }} />
                                        <Text className='regular-1' style={{ textAlign: CellAlignItems.center, flex: 1 }}>Nghỉ có phép</Text>
                                        <div style={{ borderRight: 'var(--border-grey1)' }} />
                                        <Text className='regular-1' style={{ textAlign: CellAlignItems.center, flex: 1 }}>Nghỉ không phép</Text>
                                    </div>
                                </div>
                            </TbCell>,
                            <TbCell key={'h5'} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Điểm</Text>
                            </TbCell>,
                            <TbCell key={'hlast'} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                            </TbCell>
                        ]
                    }
                </TbHeader>
                <TbBody>
                    {
                        (search ?? students).map((item, index) => {
                            const _report = session.find(e => e.studentId === item.id) ?? { studentId: item.id }
                            return <TbRow key={`${item.id}-${date.getTime()}`}>
                                {[
                                    <TbCell key={'b0-' + index} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                        <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                                            <Text className='heading-9'>{index + 1}</Text>
                                        </div>
                                    </TbCell>,
                                    <TbCell key={'b1-' + index} style={{ minWidth: '12rem' }} align={CellAlignItems.start}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{item.name ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b2-' + index} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.age ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b3-' + index} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.gender === 0 ? 'Nữ' : item.gender === 1 ? "Nam" : "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b4-' + index} style={{ minWidth: '45rem' }} align={CellAlignItems.center}>
                                        <div className="row" style={{ justifyContent: 'space-around' }}>
                                            <Checkbox style={{ borderRadius: '50%' }} size={'2rem'} value={_report?.status === 2} onChange={() => { updateReportSession({ ..._report, status: 2 }) }} />
                                            <Checkbox style={{ borderRadius: '50%' }} size={'2rem'} value={_report?.status === 1} onChange={() => { updateReportSession({ ..._report, status: 1 }) }} />
                                            <Checkbox style={{ borderRadius: '50%' }} size={'2rem'} value={_report?.status === 0} onChange={() => { updateReportSession({ ..._report, status: 0 }) }} />
                                        </div>
                                    </TbCell>,
                                    <TbCell key={'b5-' + index} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                        <Select1
                                            style={{ border: 'none' }}
                                            value={_report.score}
                                            options={Array.from({ length: 11 }).map((_, i) => { return { id: i, name: `${i}` } })}
                                            onChange={(v) => { updateReportSession({ ..._report, score: v.id }) }}
                                            placeholder="Chọn điểm"
                                        />
                                    </TbCell>,
                                    <TbCell key={'blast-' + index} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center}>
                                        <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faEllipsisH} onClick={() => { }} /></button>
                                    </TbCell>
                                ]}
                            </TbRow>;
                        })
                    }
                </TbBody>
            </Table> :
                <Text className='heading-8'>Không có lịch học</Text> :
                <Text className='heading-8'>Lớp chưa có học sinh</Text>
            }
        </div>
    </div>
})

const PopupStudentReport = forwardRef(function PopupStudentReport(data, ref) {
    const [students, setStudents] = useState([])
    const [reports, setReports] = useState([])
    const _studentController = new StudentController("student")
    const _studentIds = data.classItem.studentId?.split(",")
    const popupRef = useRef()

    const studentInforToggle = (item, _report) => {
        if (!item.isOpen && !_report) {
            SessionController.group({
                searchRaw: `@studentId:{${item.id}} @classId:{${data.classItem.id.toLowerCase().replaceAll("-", "")}}`,
                reducers: `GROUPBY 1 @status REDUCE COUNT 0 AS _count REDUCE SUM 1 @score AS totalPoint`
            }).then(res => {
                if (res) {
                    setReports([
                        ...reports,
                        {
                            studentId: item.id,
                            present: parseInt(res.data.find(e => e.status == 2)?.["_count"] ?? "0"),
                            absent: parseInt(res.data.find(e => parseInt(e.status) !== 2)?.["_count"] ?? "0"),
                            totalPoint: res.data.length ? res.data.map(e => parseInt(e.totalPoint)).reduce((a, b) => a + b) : 0
                        }
                    ])
                }
            })
        }
        setStudents(students.map(e => {
            if (e.id === item.id) return { ...e, isOpen: !item.isOpen }
            else return e
        }))
    }

    const showPopupAddReport = (item) => {
        showPopup({
            ref: popupRef,
            heading: <div className="heading-7 popup-header">Viết nhận xét học sinh {item.name}</div>,
            style: { width: '60%', height: '80%' },
            content: <PopupAddReport ref={popupRef} />
        })
    }

    useEffect(() => {
        if (_studentIds?.length) {
            _studentController.getByListId(_studentIds).then(res => {
                if (res) setStudents(res)
            })
        }
    }, [data.classItem])

    return <div className="col" style={{ flex: 1, overflow: 'hidden auto', height: '100%', width: '100%', padding: '0.8rem 0' }}>
        <Popup ref={popupRef} />
        {students.map(item => {
            const _report = reports.find(e => e.studentId === item.id)
            return <div key={item.id} className="col" style={{ borderBottom: 'var(--border-grey1)' }}>
                <div className="row" style={{ gap: '0.8rem', padding: '0.8rem 1.6rem', backgroundColor: item.isOpen ? 'var(--selected-background)' : undefined }} onClick={() => { studentInforToggle(item, _report) }}>
                    <div className="row icon-button28"><FontAwesomeIcon icon={item.isOpen ? faCaretDown : faCaretRight} /></div>
                    <Text className="semibold3">{item.name}</Text>
                </div>
                {item.isOpen ? <div className="col" style={{ padding: '0.8rem 0 0.8rem 5.2rem', gap: '0.8rem' }}>
                    {/* <div className="row" style={{ gap: '1.6rem' }}>
                        <Text className="semibold2" style={{ width: '8rem' }}>Số buổi:</Text>
                        <Text className="regular2">{_report?. ? "-" : "0"}</Text>
                    </div> */}
                    <div className="row" style={{ gap: '1.6rem' }}>
                        <Text className="semibold2" style={{ width: '8rem' }}>Đi học:</Text>
                        <Text className="regular2">{_report?.present ?? "0"}</Text>
                    </div>
                    <div className="row" style={{ gap: '1.6rem' }}>
                        <Text className="semibold2" style={{ width: '8rem' }}>Nghỉ:</Text>
                        <Text className="regular2">{_report?.absent ?? "0"}</Text>
                    </div>
                    <div className="row" style={{ gap: '1.6rem' }}>
                        <Text className="semibold2" style={{ width: '8rem' }}>Đánh giá:</Text>
                        <button type="button" className="row button-infor" onClick={() => { showPopupAddReport(item) }}>
                            <FontAwesomeIcon icon={faFile} />
                            <Text className="button-text-3">Viết đánh giá</Text>
                        </button>
                    </div>
                    <div className="row" style={{ gap: '1.6rem' }}>
                        <Text className="semibold2" style={{ width: '8rem' }}>Điểm:</Text>
                        <Text className="regular2">{_report?.totalPoint ?? "0"}</Text>
                        <button type="button" className="row button-error">
                            <FontAwesomeIcon icon={faGift} />
                            <Text className="button-text-3">Đổi điểm</Text>
                        </button>
                    </div>
                </div> : null}
            </div>
        })}
    </div>
})

const PopupAddReport = forwardRef(function PopupAddReport(data, ref) {
    const methods = useForm({ shouldFocusError: false })

    const _onSubmit = (ev) => { }

    return <div className="col" style={{ flex: 1, height: '100%' }}>
        <div className="col" style={{ flex: 1, height: '100%', padding: '2.4rem', gap: '2.4rem', overflow: 'hidden auto' }}>
            <TextFieldForm
                required
                name="name"
                register={methods.register}
                errors={methods.formState.errors}
                label="Tiêu đề"
            />
            <CKEditorForm
                control={methods.control}
                errors={methods.formState.errors}
                name="content"
                required
                label="Nhận xét"
            />
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Hủy</Text>
            <div style={{ flex: 1 }} />
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Xác nhận</Text>
            </button>
        </div>
    </div>
})
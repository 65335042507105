import React, { createRef, useRef } from 'react';
import { useEffect } from 'react'
import './main-layout.css'
import HeaderView from './header/header';
import SideBar from './sidebar/sidebar'
import { AccountActions } from '../module/account/reducer';
import { useDispatch } from 'react-redux';
import { AccountController } from '../module/account/controller';
import { Popup, showPopup } from 'wini-web-components';
import PopupLogin from '../module/account/popup-login';
import RouterBody from '../../router/router';

class Authorization {
    static loginPopup = () => { }
}
export default function MainLayout() {
    const dispatch = useDispatch()
    const loginRef = useRef<any>()

    useEffect(() => {
        if (AccountController.token()) {
            AccountActions.getInfor(dispatch)
        }
    }, [])

    useEffect(() => {
        Authorization.loginPopup = () => {
            showPopup({
                ref: loginRef,
                content: <PopupLogin ref={loginRef} />
            })
        }
    }, [])

    return <div className="main-layout">
        <Popup ref={loginRef} />
        <HeaderView />
        <SideBar />
        <RouterBody />
    </div>
}

export const showLoginPopup = () => {
        Authorization.loginPopup()
}
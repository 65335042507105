import { ToastMessage } from "wini-web-components"
import ConfigAPI from "../../../../config/configApi"
import { postData } from "../../../baseDA"
import { SessionItem } from "./da";

export class SessionController {
    static getListSimple = async (params: { searchRaw?: string, page: number, size: number, sortby?: { BY: string, DIRECTION?: 'ASC' | 'DESC' } }) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/getListSimple`, {
            data: params
        })
        if (response.code === 200) {
            return response as { totalCount: number, data: Array<SessionItem> }
        } else {
            ToastMessage.errors(response.message)
        }
    }

    static group = async (params: { searchRaw?: string, reducers: string }) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/groupBy`, {
            data: params
        })
        if (response) {
            if (response.code === 200) {
                return response as { totalCount: number, data: Array<any> }
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    static getById = async (id: string) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/getById?id=${id}`)
        if (response) {
            if (response.code === 200) {
                return response.data as SessionItem
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    static getByListId = async (ids: Array<string>) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/getByIds`, {
            data: { ids: ids }
        })
        if (response) {
            if (response.code === 200) {
                return response.data as SessionItem
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    static add = async (studentItemList: Array<SessionItem>) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/action?action=add`, {
            data: { data: studentItemList ?? [] }
        })
        if (response) {
            if (response.code === 200) {
                return response.data
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    static edit = async (studentItem: SessionItem) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/action?action=edit`, {
            data: { data: [studentItem] }
        })
        if (response) {
            if (response.code === 200) {
                return response.data as SessionItem
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    static delete = async (listStudentId: Array<string>) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/session/action?action=delete`, {
            data: { ids: listStudentId }
        })
        if (response) {
            if (response.code === 200) {
                return response.data
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }
}
import { Text } from "wini-web-components"






export default function Perform() {

    const Oder = () => {
        return (
            <>
                <div className="col" style={{ backgroundColor: '#EDF2FD', borderRadius: "1rem", gap: "1.25rem", alignItems: "flex-start", justifyContent: "center",width:'100%',padding:'1.5rem 2rem' }}>
                    <div>
                        <Text className="heading-6" style={{ color: "rgba(0, 32, 77, 1)" }}>Đơn hàng #746854764</Text>
                    </div>
                    <div className="row" style={{ gap: '3rem', alignItems: "flex-start" }}>
                        <div className="col" style={{ gap: "1rem" }}>
                            <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Người mua</Text>
                            <Text className="body-2" style={{ color: "rgba(0, 32, 77, 1)" }}>Phạm Văn An</Text>
                        </div>
                        <div className="col" style={{ gap: "1rem" }}>
                            <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Số điện thoại</Text>
                            <Text className="body-2" style={{ color: "rgba(0, 32, 77, 1)" }}>09358395345</Text>
                        </div>
                        <div className="col" style={{ gap: "1rem" }}>
                            <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Email</Text>
                            <Text className="body-2" style={{ color: "rgba(0, 32, 77, 1)" }}>nvan@gmail.com</Text>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const Banking = () => {
        return (
            <>
                <div className="col" style={{ gap: "1rem",width:'100%' ,padding:'0rem 2rem'}}>
                    <div className="row" style={{alignItems:"center",justifyContent:"flex-start",}}>
                        <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 1)" }}>Sử dụng ứng dụng mobile banking hoặc ví điện tử để quét mã</Text>
                    </div>
                    <div className="row" style={{ gap: '2.75rem',flex:"1 0 0%" }}>
                        <div className="row" style={{  alignItems: 'center',justifyContent:"center",padding:"0rem 2rem",flex:"1 0 0%" }}>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE48jfGX5hfbOZ3jMkof42sxcMiTCSX_xr-Q&s" style={{width:'60%',height:'60%'}}></img>
                        </div>
                        <div className="col" style={{ gap: "1.75rem", alignItems: "flex-start", flex: "1 0 0%" }}>
                            <div className="col" style={{ alignItems: 'flex-start', gap: "0.4rem" }}>
                                <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Ngân hàng</Text>
                                <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.8)" }}>Vietcombank - Ngân hàng thương mại cổ phần Ngoại thương Việt Nam</Text>
                            </div>
                            <div className="col" style={{ alignItems: 'flex-start', gap: "0.4rem" }}>
                                <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Số tài khoản</Text>
                                <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.8)" }}>0934759385353</Text>
                            </div>
                            <div className="col" style={{ alignItems: 'flex-start', gap: "0.rem" }}>
                                <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Người nhận</Text>
                                <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.8)" }}>Công ty Hệ thống thông tin ABC</Text>
                            </div>
                            <div className="col" style={{ alignItems: 'flex-start', gap: "0.4rem" }}>
                                <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Số tiền</Text>
                                <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.8)" }}>1.800.000</Text>
                            </div>
                            <div className="col" style={{ alignItems: 'flex-start', gap: "0.4rem" }}>
                                <Text className="subtitle-4" style={{ color: "rgba(0, 32, 77, 0.6)" }}>Nội dung chuyển khoản</Text>
                                <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.8)" }}>DH8374593458345</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <div className="col" style={{ padding: '1.5rem', gap: '2.5rem', alignItems: "center", justifyContent: "center" }}>
                {Oder()}
                {Banking()}
            </div>
        </>
    )
}
import { Route, Routes, useParams } from "react-router-dom";
import SocialHome from "../screen/module/social/home/home";
import ListCenter from "../screen/module/center/local-component/list-center";
import EduHome from "../screen/module/edu/home/home";
import EcomHome from "../screen/module/ecom/product/home";
import SocialDiscoveryView from "../screen/module/social/discovery/discovery";
import { AccountController } from "../screen/module/account/controller";
import CustomerPage from "../screen/module/social/home/local-component/customer-page";
import NewsView from "../screen/module/social/new/view";
import SettingsNews from "../screen/module/social/new/local-component/settings-details";
import CenterRegister from "../screen/module/center/local-component/register";
import CenterView from "../screen/module/center/view";
import SettingsCourse from "../screen/module/edu/course/local-component/settings-details";
import CourseView from "../screen/module/edu/course/view";
import EduListCourses from "../screen/module/edu/home/local-component/list-all-course";
import SettingsQuestion from "../screen/module/edu/question/local-component/settings-details";
import SettingsExam from "../screen/module/edu/exam/local-component/settings-details";
import ExamView from "../screen/module/edu/exam/view";
import TestingView from "../screen/module/edu/exam/local-component/testing";
import CartShopping from "../screen/layout/header/CartShopping/CartShopping";
import PayMentDetail from "../screen/layout/header/CartShopping/PayMent-Details/Payment";

export enum ViewPath {
    Default = "/",
    Center = "/Center",
    Edu = "/Edu",
    Ecom = "/Ecom",
    Discovery = "/Discovery",
    Chat = "/Chat",
    MyHomePage = "/User",
    NewsView = "/Social/News",
    NewsForm = "/Social/News/Form",
    CourseView = "/Center/Course",
    CourseForm = "/Center/Course/Form",
    Courses = "/Edu/Courses",
    EduCourseView = "/Edu/Course",
    QuestionForm = "/Center/Question/Form",
    ExamForm = "/Center/Exam/Form",
    ExamView = "/Edu/Exam",
    Testing = "/Edu/Testing",
    CardShop ="/CartShopping",
    PayMentDetail="/CartShopping/PayMentDetails",
}

export default function RouterBody() {
    return <Routes>
        <Route path={`${ViewPath.MyHomePage}/:customerId`} element={<CustomerPage />} />
        {/* unAuthorization view */}
        <Route path={ViewPath.NewsView} element={<NewsView />} />
        <Route path={ViewPath.CourseView} element={<CourseView />} />
        <Route path={ViewPath.EduCourseView} element={<CourseView />} />
        <Route path={ViewPath.ExamView} element={<ExamView />} />
        <Route path={ViewPath.Courses} element={<EduListCourses />} />
        {/* authorization view */}
        {AccountController.token() ? <>
            <Route path={ViewPath.NewsForm} element={<SettingsNews />} />
            <Route path={ViewPath.QuestionForm} element={<SettingsQuestion />} />
            <Route path={ViewPath.ExamForm} element={<SettingsExam />} />
            <Route path={`${ViewPath.MyHomePage}/:userName`} element={<CustomerPage />} />
            <Route path={`${ViewPath.Center}/Add`} element={<CenterRegister />} />
            <Route path={`${ViewPath.Center}/:centerName`} element={<CenterView />} />
            <Route path={`${ViewPath.CourseForm}/:tab`} element={<SettingsCourse />} />
            <Route path={ViewPath.Testing} element={<TestingView />} />
            <Route path={ViewPath.CardShop} element={<CartShopping/>} />
            <Route path={ViewPath.PayMentDetail} element={<PayMentDetail/>} />
        </> : <></>}
        {/* module home */}
        <Route path={"/:module?"} element={<ModuleHome />} />
    </Routes>
}

const ModuleHome = () => {
    const { module } = useParams()

    switch (module) {
        case 'Center':
            return <ListCenter />
        case 'Edu':
            return <EduHome />
        case 'Ecom':
            return <EcomHome />
        case 'Discovery':
            return <SocialDiscoveryView />
        case 'Chat':
            return <div />
        default:
            return <SocialHome />
    }
} 
import { useEffect, useRef, useState } from "react"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { CustomerController } from "../../../customer/controller"
import expertBg from '../../../../../assets/expert-bg.png'
import { Text, TextField, ToastMessage } from "wini-web-components"
import { NewStatus } from "../../new/da"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FilledSEdit } from "../../../../../assets/const/icon"
import { ViewPath } from "../../../../../router/router"
import SidebarActions from "../../../../layout/sidebar/sidebar-actions"
import { AccountController } from "../../../account/controller"
import { showLoginPopup } from "../../../../layout/main-layout"
import ListNews from "./list-news"
import ConfigAPI from "../../../../../config/configApi"
import { uploadFiles } from "../../../../baseDA"
import { uuidRegex } from "../../../../../assets/const/const-list"

export default function CustomerPage() {
    const { customerId } = useParams()
    const navigate = useNavigate()
    const userInfor = useSelector((state) => state.account.data)
    const [customer, setCustomer] = useState()
    const [selectedTab, setSelectedTab] = useState(0)
    const [fixedTabbar, setFixedTabbar] = useState()
    const isLogin = AccountController.token()
    const filePickerRef = useRef()

    const renderUI = () => {
        switch (selectedTab) {
            case 0:
                return <ListNews customer={customer} />;
            case 1:
                return <></>;
            case 2:
                return <></>;
            case 3:
                return <ListNews customer={customer} status={NewStatus.draft} />;
            default:
                break;
        }
    }

    const pickPicture = async (ev) => {
        if (ev.target.files && ev.target.files[0]) {
            const res = await uploadFiles([ev.target.files[0]])
            if (res) {
                const editRes = await CustomerController.edit({
                    ...customer,
                    pictureID: res[0].id
                })
                if (editRes) {
                    ToastMessage.success('Cập nhật ảnh bìa thành công')
                    setCustomer({
                        ...customer,
                        pictureID: res[0].id
                    })
                }
            }
        }
    }

    useEffect(() => {
        if (userInfor?.id === customerId) {
            setCustomer(userInfor)
        } else {
            CustomerController.getById(customerId).then(res => {
                if (res) setCustomer(res)
            })
        }
    }, [customerId])

    useEffect(() => {
        const handleScroll = () => {
            let _tabbar = document.getElementById('handle-tabbar-customer-page')
            if (!_tabbar) {
                document.body.querySelector('.main-layout').removeEventListener('scroll', handleScroll)
                return
            }
            _tabbar = _tabbar.getBoundingClientRect()
            const _header = document.body.querySelector('.header').getBoundingClientRect()
            if (_tabbar.y < _header.height) {
                setFixedTabbar({ position: 'fixed', top: _header.height, width: _tabbar.width, zIndex: 2 })
            } else if (_tabbar.y >= _header.height) {
                setFixedTabbar(undefined)
            }
        }
        document.body.querySelector('.main-layout').addEventListener('scroll', handleScroll)
    }, [])

    return <>
        <div className="body-sidebar left col" style={{ gap: '2.4rem' }}>
            <TextField
                prefix={<FontAwesomeIcon icon={faSearch} />}
                style={{ height: '4rem', padding: '0.8rem 1.6rem', margin: '0.4rem 0' }}
                className="search-default body-3"
                placeholder="Tìm kiếm bài viết"
            />
            <div className="row">
                <Text maxLine={1} style={{ flex: 1, width: '100%' }} className="heading-6">Bảng tin</Text>
                <button type="button" onClick={() => {
                    if (isLogin) {
                        navigate(ViewPath.NewsForm)
                    } else {
                        showLoginPopup()
                    }
                }} className="row button-infor" style={{ backgroundColor: 'transparent' }}>
                    <FilledSEdit width="1.4rem" height="1.4rem" />
                    <div className="button-text-5">Tạo bài viết</div>
                </button>
            </div>
            <div className="col" style={{ flex: 1, height: '100%', overflow: 'hidden auto', gap: '1.2rem' }}>
                {userInfor?.id ? <NavLink to={`${ViewPath.MyHomePage}/${userInfor.id}`} className={`news-bookmark-tab ${selectedTab === 0 ? 'selected' : ''}`}>
                    <div className="label-3">Trang cá nhân</div>
                </NavLink> : null}
                <NavLink to={'/'} className={`news-bookmark-tab ${selectedTab === 1 ? 'selected' : ''}`}>
                    <div className="label-3">Bảng tin</div>
                </NavLink>
                <NavLink to={''} className={`news-bookmark-tab ${selectedTab === 2 ? 'selected' : ''}`}>
                <div className="label-3">Đã lưu</div>
            </NavLink>
            </div>
            <SidebarActions />
        </div>
        <div className="col main-layout-body">
            <div style={{ position: 'relative' }}>
                <img src={customer?.pictureID ? customer.pictureID.match(uuidRegex) ? (ConfigAPI.imgUrl + customer.pictureID) : customer.pictureID : expertBg} alt='' style={{ width: '100%', height: '22rem', objectFit: 'cover' }} />
                {userInfor?.id === customerId ? <button type='button' className='row edit-button' style={{ padding: '0.6rem 1.2rem', bottom: '1.6rem', right: '1.6rem' }} onClick={() => { filePickerRef.current.click() }}>
                    <input accept={'image/jpg, image/png, image/jpeg'} ref={filePickerRef} type='file' hidden onChange={pickPicture} />
                    <FontAwesomeIcon icon={faEdit} style={{ fontSize: '1.6rem', color: '#fff' }} />
                    <Text className='button-text-3' style={{ color: '#fff' }}>Chỉnh sửa</Text>
                </button> : undefined}
            </div>
            <div className="row" style={{ justifyContent: 'center', alignItems: 'start' }}>
                <div className="col col24 col20-xxl" style={{ gap: '2.4rem', '--gutter': '0px', padding: '0 2.4rem' }}>
                    <div id='handle-tabbar-customer-page' style={{ height: '6rem' }}>
                        <div className='row' style={{ padding: '1.6rem 0 0', backgroundColor: '#fff', ...(fixedTabbar ?? {}) }}>
                            <div className="row tab-header-2 col24 col20-xxl">
                                <button className={`tab-btn label-4 ${selectedTab === 0 ? 'selected' : ''}`} onClick={() => { setSelectedTab(0) }}>
                                    Bài viết
                                </button>
                                <button className={`tab-btn label-4 ${selectedTab === 1 ? 'selected' : ''}`} onClick={() => { setSelectedTab(1) }}>
                                    Danh mục bài viết
                                </button>
                                {userInfor?.id === customerId ? <button className={`tab-btn label-4 ${selectedTab === 2 ? 'selected' : ''}`} onClick={() => { setSelectedTab(2) }}>
                                    Nháp
                                </button> : undefined}
                            </div>
                        </div>
                    </div>
                    {renderUI()}
                </div>
            </div>
        </div>
        <div className="body-sidebar right col" style={{ overflow: 'hidden auto', scrollbarWidth: 'none', padding: '2.4rem 1.6rem' }}>
            <div className="col" style={{ gap: '1.6rem' }}>
                <img src={customer?.avatarUrl ? customer.avatarUrl.match(uuidRegex) ? (ConfigAPI.imgUrl + customer.avatarUrl) : customer.avatarUrl : undefined} alt="" style={{ width: '8rem', height: '8rem', borderRadius: '50%' }} />
                <div className="col" style={{ gap: '0.4rem' }}>
                    <Text className="heading-6">{customer?.name}</Text>
                    <Text className="subtitle-3">19 Người theo dõi · 20 Đang theo dõi</Text>
                    <Text maxLine={4} className="body-2" style={{ marginTop: '0.8rem' }}>Data Guy working Banking & Finance I write (randomly & sporadically) about anything and everything that interests me or worth sharing/analysing.</Text>
                </div>
                <button type="button" className="row button-primary" style={{ borderRadius: '2.4rem' }}>
                    <Text className="button-text-3">Theo dõi</Text>
                </button>
            </div>
        </div>
    </>
}


import { faArrowRight, faEdit, faEllipsisV, faEye, faPlus, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { ComponentStatus, Dialog, DialogAlignment, Popup, Text, TextField, ToastMessage, closePopup, showDialog, showPopup } from "wini-web-components"
import { FilledBook, FilledClock, FilledEdit, FilledFileCopy, FilledNetworkCommunication, FilledPeople, FilledSetupPreferences, FilledTrashCan, OutlineHeart, OutlineShoppingCart, OutlineStar, OutlineUserProfile } from "../../../../assets/const/icon"
import { CourseController } from "../../edu/course/controller"
import { CourseStatus } from "../../edu/course/da"
import { NavLink, useNavigate } from "react-router-dom"
import { Ultis } from "../../../../Utils"
import { PostCard } from "../../../../project-component/card"
import ConfigAPI from "../../../../config/configApi"
import { useSelector } from "react-redux"
import { CenterPermisson } from "../da"
import PopupAddNewCourse from "../../edu/course/local-component/popup-add-new-course"
import { ExamController } from "../../edu/exam/controller"
import { ExamStatus } from "../../edu/exam/da"
import ExamManagment from "../../edu/exam/exam"
import QuestionManagment from "../../edu/question/question"
import { CustomerController } from "../../customer/controller"
import { RatingController } from "../../edu/rating/controller"
import ListComment from "../../social/new/local-component/list-comment"
import { ViewPath } from "../../../../router/router"

export default function CenterEduTab({ centerItem, permisson }) {
    const userInfor = useSelector((state) => state.account.data)
    const navigate = useNavigate()
    const [courses, setCourses] = useState({ totalCount: undefined, data: [] })
    const [listCustomer, setListCustomer] = useState([])
    const [commonExams, setCommonExams] = useState([])
    const ref = useRef()
    const dialogRef = useRef()

    const getCourses = async (page) => {
        let _filter = [{ field: 'centerId', operator: '=', value: centerItem.id }]
        if (permisson === CenterPermisson.member) {
            _filter.push({
                field: 'status',
                operator: '<>',
                value: CourseStatus.draft
            })
        }
        const res = await CourseController.getListSimple({ page: page ?? Math.floor((courses.data.length / 10)) + 1, take: 10, filter: _filter })
        if (res) {
            const customerIds = res.data.map(e => e.customerId)
            if (customerIds) CustomerController.getByIds(customerIds).then(cusRes => {
                if (cusRes) setListCustomer([...listCustomer, ...cusRes])
            })
            const ratingRes = await RatingController.getByLinkIds(res.data.map(e => e.id))
            setCourses({
                totalCount: res.totalCount,
                data: [...courses.data, ...res.data.filter(e => courses.data.every(el => el.id !== e.id)).map(e => {
                    const ratingData = ratingRes.find(rate => rate.linkId === e.id)
                    return {
                        ...e,
                        totalStar: ratingData?.totalStart,
                        totalComment: ratingData?.totalComment,
                    }
                })]
            })
        }
    }

    const getCommonExams = async () => {
        // const res = await ExamController.getListSimple({ page: 1, take: 5 })
        const res = await ExamController.getListSimple({ page: 1, take: 5, filter: [{ field: 'centerId', operator: '=', value: centerItem.id }] })
        if (res) {
            setCommonExams(res.data)
        }
    }

    const courseInfor = (item) => {
        const customer = listCustomer.find(e => e.id === item.customerId)
        return <div key={item.id} className="center-course-card">
            <PostCard
                to={ViewPath.CourseView + `?id=${item.id}`}
                className={`col`}
                style={{ width: '100%', padding: 0 }}
                imgUrl={ConfigAPI.imgUrl + item.thumbnailId}
                imgStyle={{ height: '18.4rem' }}
                heading={<div className="row" style={{ gap: '0.8rem', paddingBottom: '1.6rem' }}>
                    <img src={customer?.avatarUrl} alt="" style={{ width: '2.4rem', height: '2.4rem', borderRadius: '50%' }} />
                    <Text className='label-4' maxLine={1} style={{ flex: 1, width: '100%' }}>{customer?.name}</Text>
                </div>}
                title={item.name}
                content={item.description}
                actions={<div className='col' style={{ gap: '2.4rem', flex: 1, justifyContent: 'end', height: '100%' }}>
                    <div className='row' style={{ gap: '0.8rem' }}>
                        <OutlineUserProfile />
                        <Text className='button-text-3'>1k2</Text>
                        <Text className='button-text-3'>-</Text>
                        <OutlineStar />
                        <Text className='button-text-3'>{`${item.totalStar && item.totalComment ? `${(item.totalStar / item.totalComment).toFixed(1)} (${item.totalComment})` : '0 (0)'}`}</Text>
                    </div>
                    <div className='row'>
                        <Text className='heading-7' style={{ flex: 1, width: '96%' }}>{item.price ? Ultis.money(item.price) : ''}</Text>
                        <button type='button' className='row icon-button32'><OutlineHeart width='2rem' height='2rem' /></button>
                        <button type='button' className='row icon-button32'><OutlineShoppingCart width='2rem' height='2rem' /></button>
                    </div>
                </div>}
            />
            {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <>
                <NavLink to={ViewPath.CourseForm + `/overview?id=${item.id}`} className="icon-button32 row">
                    <FontAwesomeIcon icon={faEdit} style={{ color: item.thumbnailId ? '#fff' : undefined }} />
                </NavLink>
                <button type="button" className="icon-button32 row" onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        status: ComponentStatus.WARNING,
                        alignment: DialogAlignment.center,
                        title: 'Bạn chắc chắn muốn xóa khóa học này',
                        content: 'Khóa học này sẽ bị xóa khỏi danh sách khóa học trong trung tâm của bạn',
                        onSubmit: () => {
                            CourseController.delete([item.id]).then((res) => {
                                if (res) {
                                    ToastMessage.success("Xoas khóa học thành công")
                                    setCourses({
                                        data: courses.data.filter(e => e.id !== item.id),
                                        totalCount: courses.totalCount - 1
                                    })
                                }
                            })
                        }
                    })
                }}>
                    <FontAwesomeIcon icon={faTrashCan} style={{ color: item.thumbnailId ? '#fff' : undefined }} />
                </button>
            </> : null}
        </div>
    }

    const popupAddNewCourse = () => {
        showPopup({
            ref: ref,
            heading: <div className='popup-header heading-7'>Tạo mới course</div>,
            content: <PopupAddNewCourse ref={ref} centerId={centerItem.id} customerId={userInfor.id} />,
        })
    }

    const confirmDelete = (item) => {
        showDialog({
            ref: dialogRef,
            status: ComponentStatus.WARNING,
            alignment: DialogAlignment.center,
            title: 'Bạn chắc chắn muốn xóa đề thi này',
            content: 'Đề thi này sẽ bị xóa khỏi thư viện đề thi của bạn',
            onSubmit: () => {
                ExamController.delete([item.id]).then(res => {
                    if (res) getCommonExams()
                })
            }
        })
    }

    const showStoreExams = () => {
        showPopup({
            ref: ref,
            style: { height: '80%', width: '80%' },
            heading: <div className="heading-6 popup-header">Kho đề thi</div>,
            content: <ExamManagment centerId={centerItem.id} permisson={permisson} />
        })
    }

    const showStoreQuestion = () => {
        showPopup({
            ref: ref,
            style: { height: '80%', width: '80%' },
            heading: <div className="heading-6 popup-header">Kho câu hỏi</div>,
            content: <QuestionManagment centerId={centerItem.id} />
        })
    }

    useEffect(() => {
        if (centerItem) {
            getCourses(1)
            getCommonExams()
        }
    }, [centerItem])

    return <>
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='col' style={{ margin: '2.4rem 0', width: '100%', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <Text className="heading-6" style={{ flex: 1, gap: '0.8rem' }}>Đề thi phổ biến</Text>
                <button type="button" onClick={showStoreExams} className="row button-primary" style={{ padding: '0.6rem 1.2rem', borderRadius: '0.8rem' }}>
                    <Text className="button-text-3">Kho đề</Text>
                </button>
                {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button type="button" onClick={showStoreQuestion} className="row button-grey" style={{ padding: '0.6rem 1.2rem' }}>
                    <Text className="button-text-3">Kho câu hỏi</Text>
                </button> : undefined}
            </div>
            <div className="col" style={{ alignItems: 'center', gap: '0.8rem' }}>
                {
                    commonExams.length ? commonExams.map((item, i) => {
                        return <div key={item.id} className="row" style={{ gap: '1.6rem', padding: '1.6rem', borderBottom: 'var(--border-grey1)', width: '100%' }}>
                            <div className="col" style={{ gap: '0.8rem', flex: 1 }}>
                                <div className="row" style={{ gap: '0.8rem' }}>
                                    <Text className="heading-7">{item.name}</Text>
                                    <Text className="semibold2" style={{ color: item.status === ExamStatus.real ? 'var(--primary-color)' : '#00204D99' }}>
                                        {item.status ? item.status === ExamStatus.real ? "Cấp chứng chỉ" : "Thi thử" : 'Bản nháp'}
                                    </Text>
                                </div>
                                <div className="row" style={{ gap: '1.2rem' }}>
                                    <div className="row" style={{ gap: '0.4rem' }}>
                                        <FilledClock />
                                        <Text className="subtitle-3">{item.time} phút</Text>
                                    </div>
                                    <div className="row" style={{ gap: '0.4rem' }}>
                                        <FilledBook />
                                        <Text className="subtitle-3">{item.quantityQuestion}</Text>
                                    </div>
                                    <div className="row" style={{ gap: '0.4rem' }}>
                                        <OutlineStar />
                                        <Text className="subtitle-3">0(0)</Text>
                                    </div>
                                </div>
                            </div>
                            {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <>
                                <button className="row icon-button32" onClick={() => { navigate(ViewPath.ExamForm + `?id=${item.id}`) }}><FilledEdit width="2rem" height="2rem" /></button>
                                <button className="row icon-button32" onClick={() => { confirmDelete(item) }} ><FilledTrashCan width="2rem" height="2rem" /></button>
                            </> : <NavLink to={ViewPath.QuestionForm + `?id=${item.id}`} className={'row button-infor border'} style={{ borderRadius: '0.8rem' }}>
                                <Text className="button-text-3">Vào thi</Text>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </NavLink>}
                        </div>
                    }) : <div className="row" style={{ justifyContent: 'center' }}>
                        <Text className="semibold2">Trung tâm chưa có đề thi nào.</Text>
                    </div>
                }
            </div>
        </div>
        <div className="col divider" />
        <div className='col' style={{ margin: '1.2rem 0', width: '100%', gap: '0.8rem' }}>
            {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <Text className="heading-6">Danh sách khóa học</Text> : undefined}
            <div className="row" style={{ gap: '1.6rem', marginTop: '1.6rem' }}>
                <TextField
                    style={{ backgroundColor: 'var(--background)', height: '4rem', flex: 1, maxWidth: '40rem' }}
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#667994', marginTop: '0.1rem' }} />}
                    placeholder="Tìm khóa học"
                />
                <button type="button" className="row" style={{ gap: '0.8rem' }}>
                    <FilledSetupPreferences width="2.4rem" height="2.4rem" />
                    <Text className="button-text-2" style={{ color: '#00204D99' }}>Bộ lọc</Text>
                </button>
                <div style={{ flex: 1 }} />
                {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button type='button' onClick={popupAddNewCourse} className='row button-primary' style={{ borderRadius: '0.8rem' }}>
                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.4rem' }} />
                    <Text className='button-text-3'>Tạo mới</Text>
                </button> : null}
            </div>
            {
                courses.data.length ? undefined : <div className="row" style={{ justifyContent: 'center', padding: '1.6rem' }}>
                    <Text className="semibold2">Trung tâm chưa có khóa học nào.</Text>
                </div>
            }
            <div className="row scrollwebkit" style={{ marginTop: '1.6rem', padding: '1.2rem 0', gap: '2.4rem', alignItems: 'stretch', overflow: 'auto hidden', scrollbarWidth: 'auto' }}>
                {courses.data.map((item) => courseInfor(item))}
            </div>
        </div>
        <div className="col divider" />
        {/* <div className='col' style={{ margin: '1.2rem 0', width: '100%', gap: '2.4rem' }}>
            <Text className="heading-6" style={{ flex: 1, gap: '0.8rem' }}>Đánh giá từ học viên</Text>
            <ListComment rating />
        </div> */}
    </>
}
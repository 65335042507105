import { forwardRef, useEffect, useRef, useState } from "react";
import { CellAlignItems, Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from "wini-web-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FilledSetupPreferences } from "../../../../../assets/const/icon";
import { StudentController } from "../../student/controller";
import { CustomerController } from "../../../customer/controller";
import { useForm } from "react-hook-form";
import { Select1Form, TextFieldForm } from "../../../../../project-component/component-form";
import { validateForm, ValidateType } from "../../../../../project-component/validate";
import { ClassController } from "../../class/controller";

export default function PopupManageStudent({ classId }) {
    const [data, setData] = useState()
    const [students, setStudents] = useState([])
    const [parents, setParents] = useState([])
    const dialogRef = useRef()
    const popupRef = useRef()
    const _studentController = new StudentController("student")

    const showPopupAddEditStudentInfor = (item) => {
        showPopup({
            ref: popupRef,
            heading: <div className="popup-header heading-7">{item ? "Sửa thông tin học sinh" : "Thêm học sinh"}</div>,
            style: { height: '80%', width: '68%' },
            content: <PopupAddEditStudentInfor
                ref={popupRef}
                item={item}
                classItem={data}
                onSubmit={(newItem) => {
                    setStudents([...students, newItem])
                    if (parents.every(e => e.id !== newItem.parentId)) CustomerController.getByIds([newItem.parentId]).then(pRes => {
                        if (pRes) setParents([...parents, ...pRes])
                    })
                }}
            />
        })
    }

    useEffect(() => {
        if (data?.studentId?.length) {
            _studentController.getByListId(data.studentId.split(",")).then(res => {
                if (res) {
                    setStudents(res)
                    const _parentIds = []
                    res.forEach(e => {
                        if (!_parentIds.includes(e.id)) _parentIds.push(e.parentId)
                    })
                    CustomerController.getByIds(_parentIds).then(pRes => {
                        if (pRes) setParents(pRes)
                    })
                }
            })
        }
    }, [data])


    useEffect(() => {
        if (classId) ClassController.getById(classId).then(res => {
            if (res) setData(res)
        })
    }, [classId])

    return <div className="col" style={{ flex: 1, gap: '1.6rem', padding: '2.4rem' }}>
        <Dialog ref={dialogRef} />
        <Popup ref={popupRef} />
        <div className="row">
            <TextField style={{ maxWidth: '32rem' }} placeholder="Tìm kiếm " prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#00204D99' }} />} />
            <div className="row divider" style={{ height: '2.4rem' }} />
            <button type="button" className="row button-grey" style={{ gap: '0.8rem', cursor: 'pointer' }}>
                <FilledSetupPreferences />
                <Text className="button-text-3" style={{ color: '#00204D99' }}>Bộ lọc</Text>
            </button>
            <div style={{ flex: 1, padding: '0 0.4rem' }} />
            <button type="button" className="row button-infor" onClick={() => { showPopupAddEditStudentInfor() }}>
                <FontAwesomeIcon icon={faPlus} />
                <Text className="button-text-3">Thêm học sinh</Text>
            </button>
        </div>
        <div style={{ flex: 1, width: '100%', height: '100%', overflow: 'auto' }}>
            <Table>
                <TbHeader>
                    {
                        [
                            <TbCell key={'h0'} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                    <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                                    <Text className='heading-9'>STT</Text>
                                </div>
                            </TbCell>,
                            <TbCell key={'h1'} style={{ minWidth: '12rem' }} align={CellAlignItems.start}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Tên</Text>
                            </TbCell>,
                            <TbCell key={'h2'} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Tuổi</Text>
                            </TbCell>,
                            <TbCell key={'h3'} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Giới tính</Text>
                            </TbCell>,
                            <TbCell key={'h4'} style={{ minWidth: '12rem' }} align={CellAlignItems.start}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Tên phụ huynh</Text>
                            </TbCell>,
                            <TbCell key={'h5'} style={{ minWidth: '10rem' }} align={CellAlignItems.start}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Email phụ huynh</Text>
                            </TbCell>,
                            <TbCell key={'h6'} style={{ minWidth: '8rem' }} align={CellAlignItems.start}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Số điện thoại phụ huynh</Text>
                            </TbCell>,
                            <TbCell key={'hlast'} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Action</Text>
                            </TbCell>
                        ]
                    }
                </TbHeader>
                <TbBody>
                    {
                        students.map((item, index) => {
                            const _parentInfor = parents.find(e => e.id === item.parentId)
                            return <TbRow key={`${item.id}`}>
                                {[
                                    <TbCell key={'b0-' + index} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                        <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                                            <Text className='heading-9'>{index + 1}</Text>
                                        </div>
                                    </TbCell>,
                                    <TbCell key={'b1-' + index} style={{ minWidth: '12rem' }} align={CellAlignItems.start}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{item.name ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b2-' + index} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.age ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b3-' + index} style={{ minWidth: '4rem' }} align={CellAlignItems.center}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.gender === 0 ? 'Nữ' : item.gender === 1 ? "Nam" : "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b4-' + index} style={{ minWidth: '12rem' }} align={CellAlignItems.start}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{_parentInfor?.name ?? _parentInfor?.userName ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b5-' + index} style={{ minWidth: '10rem' }} align={CellAlignItems.start}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{_parentInfor?.email ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'b6-' + index} style={{ minWidth: '8rem' }} align={CellAlignItems.start}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{_parentInfor?.mobile ?? "-"}</Text>
                                    </TbCell>,
                                    <TbCell key={'blast-' + index} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center}>
                                        <div className='row' style={{ gap: '0.6rem', justifyContent: 'center' }}>
                                            <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faEdit} onClick={() => { showPopupAddEditStudentInfor(item) }} /></button>
                                            <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faTrashCan} onClick={(ev) => {
                                                showDialog({
                                                    ref: dialogRef,
                                                    alignment: DialogAlignment.center,
                                                    status: ComponentStatus.WARNING,
                                                    title: 'Bạn chắc chắn muốn xóa học sinh ' + item.name + ' khỏi lớp',
                                                    onSubmit: async () => {
                                                        const res = await _studentController.delete([item.id])
                                                        if (res.code === 200) {
                                                            ToastMessage.success(`Xóa học sinh ${item.name} thành công`)
                                                            setStudents(students.data.filter(e => e.id !== item.id))
                                                        } else {
                                                            ToastMessage.errors(res.message)
                                                        }
                                                    }
                                                })
                                            }} /></button>
                                        </div>
                                    </TbCell>
                                ]}
                            </TbRow>;
                        })
                    }
                </TbBody>
            </Table>
        </div>
    </div>
}



const PopupAddEditStudentInfor = forwardRef(function PopupAddEditStudentInfor(data, ref) {
    const methods = useForm({ shouldFocusError: false })
    const [parent, setParent] = useState()
    const dialogRef = useRef()
    const _studentController = new StudentController("student")

    const _onSubmit = async (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: `Bạn chắc chắn muốn ${data.item ? "sửa thông tin" : "thêm"} học sinh ` + ev.name,
            onSubmit: async () => {
                ev.id ??= window.crypto.randomUUID().replaceAll("-", "")
                if (typeof ev.age === "string") ev.age = parseInt(ev.age)
                const res = await _studentController.add([ev])
                if (res) {
                    if (!data.item) {
                        const _studentIds = data.classItem.studentId?.split(",") ?? []
                        const classRes = await ClassController.edit([{ ...data.classItem, studentId: [..._studentIds, ev.id].join(",") }])
                        if (!classRes) return undefined
                    }
                    ToastMessage.success(`${data.item ? "Sửa thông tin" : "Thêm"} học sinh ${ev.name} thành công`)
                    data.onSubmit(ev)
                    closePopup(ref)
                }
            }
        })
    }

    useEffect(() => {
        if (data.item) Object.keys(data.item).forEach((prop) => {
            methods.setValue(prop, data.item[prop])
        })
    }, [data.item])

    useEffect(() => {
        const _parentId = methods.getValues('parentId')
        if (_parentId) {
            CustomerController.getByIds([_parentId]).then(cusRes => {
                if (cusRes) setParent(cusRes[0])
            })
        } else if (parent) setParent(undefined)
    }, [methods.watch('parentId')])

    return <div className="col" style={{ flex: 1 }}>
        <Dialog ref={dialogRef} />
        <div className="col" style={{ flex: 1, gap: '1.6rem', padding: '2.4rem', overflow: 'hidden auto' }}>
            <TextFieldForm
                required
                name="name"
                errors={methods.formState.errors}
                register={methods.register}
                label="Tên học sinh"
            />
            <div className="row" style={{ gap: '1.6rem' }}>
                <TextFieldForm
                    required
                    name="age"
                    type="number"
                    errors={methods.formState.errors}
                    register={methods.register}
                    label="Tuổi học sinh"
                />
                <Select1Form
                    required
                    readonly
                    name="gender"
                    errors={methods.formState.errors}
                    control={methods.control}
                    label="Giới tính"
                    options={[{ id: 1, name: 'Nam' }, { id: 0, name: 'Nữ' }]}
                />
            </div>
            <Select1Form
                required
                showClearValueButton
                name="parentId"
                errors={methods.formState.errors}
                control={methods.control}
                label="Email phụ huynh"
                placeholder="Nhập email phụ huynh"
                options={parent ? [
                    {
                        id: parent.id,
                        name: <div className="row" style={{ gap: '0.4rem' }}>
                            <img src={parent?.avatarUrl} alt="" style={{ borderRadius: '50%', width: '3.6rem', height: '3.6rem' }} />
                            <div className='col' style={{ flex: 1 }}>
                                <Text className='semibold2'>{parent?.name ?? parent?.userName}</Text>
                                <Text className='regular1'>{parent?.email}</Text>
                            </div>
                        </div>
                    }] : []}
                handleSearch={async (ev) => {
                    const res = validateForm({
                        list: [{ Name: 'mail', Validate: [{ type: ValidateType.email }] }],
                        formdata: { 'mail': ev.trim() }
                    })
                    if (!res) {
                        const res = await CustomerController.getIDByEmail(ev.trim())
                        if (res) {
                            const ids = res.split(",")
                            const cusRes = await CustomerController.getByIds(ids)
                            if (cusRes) {
                                return cusRes.map(e => {
                                    return {
                                        id: e.id,
                                        name: <div className="row" style={{ gap: '0.8rem', padding: '1.6rem 0.2rem' }}>
                                            <img src={e?.avatarUrl} alt="" style={{ borderRadius: '50%', width: '4.8rem', height: '4.8rem' }} />
                                            <div className='col' style={{ gap: '0.4rem', flex: 1 }}>
                                                <Text className='title-3'>{e?.name ?? e?.userName}</Text>
                                                <Text className='subtitle-3'>{e?.email}</Text>
                                            </div>
                                        </div>
                                    }
                                })
                            }
                        }
                    }
                    return []
                }}
            />
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Hủy</Text>
            <div style={{ flex: 1 }} />
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">{data.item ? "Xác nhận" : "Thêm"}</Text>
            </button>
        </div>
    </div >
})
import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form";
import { closePopup, Text } from "wini-web-components";
import { TextFieldForm } from "../../../../../project-component/component-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

export default function EditVideo({ data, onChange }) {
    const [listVideo, setListVideo] = useState([])

    useEffect(() => {
        setListVideo((data?.content ?? "").split(",").filter((_l) => _l.length))
    }, [data])

    return <div className="col" style={{ gap: '2.4rem' }}>
        {listVideo.map((l) => <Clip key={l} url={l} onDelete={() => {
            const _updateList = listVideo.filter(e => e !== l)
            setListVideo(_updateList)
            onChange(_updateList.join(","))
        }} />)}
    </div>
}

function Clip({ url, onDelete }) {
    const videoRef = useRef();

    useEffect(() => {
        videoRef.current?.load();
    }, [url]);

    return (<div style={{ padding: '2rem', position: 'relative' }}>
        <iframe width={'100%'} style={{ aspectRatio: '2 / 1.2' }}
            src={url}>
        </iframe>
        <button onClick={onDelete} type="button" className="row icon-button40" style={{ position: 'absolute', right: 0, top: 0, backgroundColor: "#fff", borderRadius: '50%' }}>
            <FontAwesomeIcon icon={faXmarkCircle} />
        </button>
    </div>
    );
}

const PopupEmbedVideoLink = forwardRef(function PopupEmbedVideoLink(data, ref) {
    const methods = useForm({ shouldFocusError: false })

    const _onSubmit = (ev) => {
        data.onSubmit(ev.link)
        closePopup(ref)
    }

    return <div className="col">
        <div className="col" style={{ padding: '2.4rem' }}>
            <TextFieldForm
                required
                register={methods.register}
                errors={methods.formState.errors}
                name="link"
                placeholder="Nhập link youtube embed"
            />
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Hủy</Text>
            <div style={{ flex: 1 }} />
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Lưu</Text>
            </button>
        </div>
    </div>
})

export { PopupEmbedVideoLink }
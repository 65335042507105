import { editorConfiguration } from "../../../../../assets/const/const-list"
import DefaultCkEditor from "../../../../../project-component/ckeditor"

export default function EditParagraph({ data, onChange }) {
    return <DefaultCkEditor
        className="lesson-paragraph-ck-editor"
        config={editorConfiguration}
        style={{ flex: 1, height: '100%' }}
        value={data?.content ?? ''}
        onBlur={(v) => {
            console.log(v)
            if (onChange) onChange({
                ...data,
                content: v
            })
        }}
    />
}
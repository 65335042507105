import { useEffect, useRef, useState } from "react";
import { TopicController } from "../../../../module/topic/controller";
import { Checkbox, Popup, showPopup, Text } from "wini-web-components";
import Perform from "./Perform";



export default function Details() {
  const ref = useRef();
  const Product = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
      TopicController.getListSimple({ page: 1, take: 8 }).then(res => {
        if (res) setData(res.data)
      })
    }, [])
    return (
      <>
        <div className="col" style={{ gap: '1.5rem', borderBottom: '1px solid rgba(0, 53, 128, 0.08)', width: '100%', paddingBottom: "1.75rem " }}>
          <div className="row">
            <Text className="heading-6" style={{ color: 'rgba(0, 32, 77, 1)' }}>Sản Phẩm</Text>
          </div>
          <div className="col" style={{overflow:'auto',height: "20rem",}}>
          <div className="col" style={{ gap: '1.5rem', paddingRight:' 2rem'}}>
            {data.map((item, id) => (
              <div className="row" style={{ gap: '1.75rem', alignItems: "flex-start" }}>
                <div className="col" style={{ gap: '1rem', flex: '1' }}>
                  <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.9)" }}>{item.name}</Text>
                  <Text className="body-3" style={{ color: "rgba(0, 32, 77, 0.8)" }}>Shop: spagreen  |  Khóa học</Text>
                </div>
                <div className="row" style={{ justifyContent: "flex-end", }}>
                  <Text className="button-text-3">1.463.000đ</Text>
                </div>
              </div>
            ))}
          </div>
          </div>
          <div className="row" style={{paddingRight:"2rem"}}>
            <Text className="label-1" style={{ color: 'rgba(0, 32, 77, 0.9)', flex: '1 0 0%' }}>Tổng giá sản phẩm</Text>
            <Text className="heading-7" style={{ color: "rgba(0, 32, 77, 0.9)" }}>3.375.000đ</Text>
          </div>
        </div>
      </>
    )
  }

  const Transport = () => {

    return (
      <>
        <div className="col" style={{ padding: "1.75rem 0rem", borderBottom: "1px solid rgba(0, 53, 128, 0.08)", width: "100%", gap: '1.25rem' }}>

          <Text className="heading-6" style={{ color: "rgba(0, 32, 77, 1)" }}>Vận Chuyển</Text>

          <div className="col" style={{ gap: "1.25rem", width: "100%" }}>

            <div className="row" style={{ gap: "1rem", }}>
              <Text className="heading-8" style={{ color: "rgba(0, 32, 77, 0.9)", flex: "1 0 0%" }}>Phí Cố định</Text>
              <Text style={{ color: "rgba(0, 32, 77, 0.9)" }}>-</Text>
            </div>
            <div className="row" style={{ gap: "1rem" }}>
              <Text className="heading-8" style={{ color: "rgba(0, 32, 77, 0.9)", flex: "1 0 0%" }}>Phí Ship nhanh</Text>
              <Text style={{ color: "rgba(0, 32, 77, 0.9)" }}>-</Text>
            </div>
            <div className="row" style={{ gap: "1rem" }}>
              <Text className="heading-8" style={{ color: "rgba(0, 32, 77, 0.9)", flex: "1 0 0%" }}>Tổng phí ship</Text>
              <Text style={{ color: "rgba(0, 32, 77, 0.9)" }}>-</Text>
            </div>

          </div>

        </div>
      </>
    )
  }


  const showMakePayment = () => {
    showPopup({
      ref: ref,
      heading: <div className="popup-header col" style={{ alignItems: 'start', padding: '1.5rem 2.5rem', borderBottom: "1px solid rgba(0, 53, 128, 0.08)" }}>
        <Text className="heading-6">Thực hiện thanh toán</Text>

      </div>,
      style: { width: "60%", height: "100%" },
      content: <Perform ref={ref} />
    })
  }

  return (
    <>
      <Popup ref={ref} />
      <div className="col" style={{ gap: '2.75rem' }}>
        <div className="row">
          <Text className="heading-4" style={{ color: "rgba(0, 32, 77, 1)" }}>Đơn hàng của bạn</Text>
        </div>
        <div className="col" style={{ padding: "2.5rem 2.75rem 2.75rem 2.75rem", backgroundColor: "#F2F5F8", borderRadius: "1rem", alignItems: "flex-start", gap: "0.5rem" }}>

          <Product></Product>


          <Transport></Transport>

          <div className="row" style={{ gap: "1.25rem", padding: "1.5rem 0rem", width: "100%" }}>
            <Text className="heading-6" style={{ color: "rgba(0, 32, 77, 1)", flex: "1 0 0%" }}>Tổng Cộng</Text>
            <Text className="heading-6" style={{ color: "rgba(0, 32, 77, 1)" }}>3.425.000đ</Text>
          </div>
          <div className="col" style={{ gap: "1.25rem", padding: "1.5rem 0rem" }}>
            <Text className="button-text-3" style={{ color: "rgba(0, 32, 77, 0.8)" }}>Dữ liệu cá nhân của bạn sẽ được sử dụng để xử lý đơn đặt hàng, hỗ trợ trải nghiệm của bạn trên
              trang web này và cho các mục đích khác được mô tả trong<Text className="button-text-3" style={{ color: "rgba(54, 106, 226, 1)" }}> chính sách bảo mật của chúng tôi.</Text>
            </Text>
            <div className="row" style={{ gap: "1rem" }}>
              <Checkbox size={17}></Checkbox>
              <div className="row" style={{ gap: "0.25rem" }}>
                <Text className="button-text-3" style={{ color: "rgba(0, 32, 77, 0.9)" }}>Tôi đã đọc và đồng ý với </Text>
                <Text className="button-text-3" style={{ color: "rgba(54, 106, 226, 1)" }}>điều khoản và điều kiện.</Text>
              </div>
            </div>
          </div>
          <div className="row" style={{ alignItems: 'center', justifyContent: "center", borderRadius: "2.75rem", backgroundColor: "#366AE2" }}>
            <Text className="button-text-1" style={{ color: "#FFF", padding: "1rem 2.25rem" }} onClick={showMakePayment}>Thanh Toán</Text>
          </div>
        </div>
      </div>
    </>
  )
}
import { ToastMessage } from "wini-web-components"
import ConfigAPI from "../../../../config/configApi"
import { postData } from "../../../baseDA"
import { StudentItem, StudentReportItem } from "./da"

export class StudentController {
    private module: string
    constructor(_module: "student" | "studentReport") {
        this.module = _module
    }

    getListSimple = async (params: { searchRaw?: string, page: number, size: number, sortby?: { BY: string, DIRECTION?: 'ASC' | 'DESC' } }) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/getListSimple`, {
            data: params
        })
        if (response) {
            if (response.code === 200) {
                return response as { totalCount: number, data: Array<StudentItem | StudentReportItem> }
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    group = async (params: { searchRaw?: string, reducers: string }) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/groupBy`, {
            data: params
        })
        if (response) {
            if (response.code === 200) {
                return response as { totalCount: number, data: Array<any> }
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    getById = async (id: string) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/getById?id=${id}`)
        if (response) {
            if (response.code === 200) {
                return response.data as StudentItem | StudentReportItem
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    getByListId = async (ids: Array<string>) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/getByIds`, {
            data: { ids: ids }
        })
        if (response) {
            if (response.code === 200) {
                return response.data as Array<StudentItem | StudentReportItem>
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    add = async (studentItemList: Array<StudentItem | StudentReportItem>) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/action?action=add`, {
            data: { data: studentItemList ?? [] }
        })
        if (response) {
            if (response.code === 200) {
                return response.data
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    edit = async (studentItem: StudentItem | StudentReportItem) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/action?action=edit`, {
            data: { data: [studentItem] }
        })
        if (response) {
            if (response.code === 200) {
                return response.data as StudentItem | StudentReportItem
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }

    delete = async (listStudentId: Array<string>) => {
        const response = await postData(ConfigAPI.winiUrl + `ebig/${this.module}/action?action=delete`, {
            data: { ids: listStudentId }
        })
        if (response) {
            if (response.code === 200) {
                return response.data
            } else {
                ToastMessage.errors(response.message)
            }
        }
        return null
    }
}
import { Link, NavLink } from "react-router-dom"
import { Checkbox, Text, TextField } from "wini-web-components"
import ListCardShop from "../../../module/social/discovery/local-component/list-cartshop"





export default function CartShopping() {

    const CartShop = () => {
        return (
            <>
                <TextField
                    className='placeholder-2'
                    placeholder='nhập mã khuyến mại'
                  
                    style={{ height: '4rem', backgroundColor: '#FFF', border: "1px solid rgba(0, 53, 128, 0.20)", borderRadius: "1rem", }}
                />

            </>
        )
    }

    const Promotional = () => {
        return (
            <>
                <NavLink className="row" style={{ justifyContent: "center", backgroundColor: "#EDF2FD", borderRadius: "5rem",padding:"0rem 2rem",height:'4rem',flex:"1" }}>
                    <Text className="button-text-3" style={{ pading: '1rem 1.5rem', color: "#366AE2" }}>Sử dụng mã </Text>
                </NavLink>
            </>
        )
    }
    const Discount = () => {
        return (
            <>
                <Link to="../CartShopping/PayMentDetails" className="row" style={{ justifyContent: "center", backgroundColor: "#366AE2", borderRadius: "5rem" ,padding:"0rem 1rem",height:'4rem',flex:"1" }}>
                    <Text className="button-text-3" style={{ pading: '0rem 1.5rem', color: "#FFF" }}>Đặt hàng</Text>
                </Link>
            </>
        )
    }
    const UpdateCart = () => { 
        return(
        <>
        
            <NavLink className="row" style={{ justifyContent: "center", backgroundColor: "#EDF2FD", borderRadius: "5rem" ,padding:"0rem 2.5rem",height:'4rem',flex:"1"}}>
                <Text className="button-text-3" style={{ pading: '0rem 1.5rem', color: "#366AE2" }}>Cập nhật giỏ hàng</Text>
            </NavLink>
        </>
        )
    }

    const Oder = () => {
        return(
        <>
            <div className="col" style={{ gap: '1.4rem' }}>
                <div className="row" style={{ justifyContent: 'start', padding: "1.5rem 0rem", borderBottom: "1px solid rgba(0, 53, 128, 0.08)" }}>
                    <Text className="comp-text heading-6" style={{ color: "rgba(0, 0, 0, 1)" }}>Đơn Hàng</Text>
                </div>
                <div className="col">
                    <div className="row" style={{ justifyContent: "space-between", padding: "1.2rem 0rem" }}>
                        <Text className="comp-text label-1" style={{ color: "rgba(0, 32, 77, 0.9)" }}>Tổng giá sản phẩm</Text>
                        <Text className="comp-text button-text-1" style={{ color: "rgba(0, 0, 0, 1)" }}>1.325.000đ</Text>
                    </div>
                    <div className="row" style={{ justifyContent: "space-between", padding: "1.2rem 0rem" }}>
                        <Text className="comp-text label-1" style={{ color: "rgba(0, 32, 77, 0.9)" }}>VAT</Text>
                        <Text className="comp-text button-text-1" style={{ color: "rgba(0, 0, 0, 1)" }}>53.000đ</Text>
                    </div>
                    <div className="row" style={{ justifyContent: "space-between", padding: "1.2rem 0rem" }}>
                        <Text className="comp-text label-1" style={{ color: "rgba(0, 32, 77, 0.9)" }}>Khuyến mãi</Text>
                        <Text className="comp-text button-text-1" style={{ color: "rgba(0, 0, 0, 1)" }}>-50.000đ</Text>
                    </div>
                    <div className="row" style={{ justifyContent: "space-between", padding: "1.2rem 0rem" }}>
                        <Text className="comp-text label-1" style={{ color: "rgba(0, 32, 77, 0.9)" }}>Phí ship (cố định)</Text>
                        <Text className="comp-text button-text-1" style={{ color: "rgba(0, 0, 0, 1)" }}>-đ</Text>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: "space-between", padding: "1.5rem 0rem", borderTop: "1px solid rgba(0, 53, 128, 0.08)" }}>
                    <Text className="comp-text heading-6" style={{ color: "rgba(0, 0, 0, 1)" }}>Đơn Hàng</Text>
                    <Text className="comp-text heading-6" style={{ color: "rgba(0, 0, 0, 1)" }}>1.375.000đ</Text>

                </div>
            </div>

        </>
        )
    }
    return (
        <>
            <div className="col main-layout-body fillH" >
                <div className="col" style={{flex:'1 0 0%',height:'100%',overflow:'hidden auto'}}>
                  <div className="row" style={{width:'100%',justifyContent:"center"}}>
                  <div className="col col24 col16-xxl col16-xl col18-lg col20-md " style={{ padding: "2rem 3.2rem",gap:'2rem',gutter:"0px" }}>
                      <div className="col col" style={{ gap: '2rem',position:'relative' }}>
                        
                        <div className="col" style={{ justifyContent: "flex-start",position:'sticky',}}>
                            <Text className="comp-text heading-4" style={{ color: 'rgba(0, 32, 77, 1)' }}>Giỏ Hàng</Text>
                        </div>
                        <div className="col" style={{width:"100%",position:'relative'}}>
                              <div className="row" style={{width:"100%" ,backgroundColor:' #F2F5F8', height:"4.5rem",alignSelf:'stretch',position:"sticky"}}>
                                   <div className="row" style={{justifyContent:"center",padding:'0rem 1.75rem',width:'5%'}}>
                                      <Checkbox></Checkbox>
                                   </div>
                                   <div className="row" style={{justifyContent:"flex-start",padding:'0rem 1.75rem',width:'35%'}}>
                                    <Text className="comp-text subtitle-3" style={{color:"rgba(0, 32, 77, 0.6)"}}>Thông tin sản phẩm</Text>
                                   </div>
                                   <div className="row" style={{justifyContent:"flex-end",padding:'0rem 1.75rem',width:'12.5%'}}>
                                    <Text className="comp-text subtitle-3" style={{color:"rgba(0, 32, 77, 0.6)",}}>Đơn vị tính</Text>
                                   </div>
                                   <div className="row" style={{justifyContent:"flex-end",padding:'0rem 1.75rem',width:'12.5%'}}>
                                    <Text className="comp-text subtitle-3" style={{color:"rgba(0, 32, 77, 0.6)"}}>Đơn giá</Text>
                                   </div>
                                   <div className="row" style={{justifyContent:"flex-end",padding:'0rem 1.75rem',width:'12.5%'}}>
                                    <Text className="comp-text subtitle-3" style={{color:"rgba(0, 32, 77, 0.6)"}}>VAT</Text>
                                   </div>
                                   <div className="row" style={{justifyContent:"flex-end",padding:'0rem 1.75rem',width:'12.5%'}}>
                                    <Text className="comp-text subtitle-3" style={{color:"rgba(0, 32, 77, 0.6)"}}>Thành tiền</Text>
                                   </div>
                                   <div className="row" style={{justifyContent:"center",padding:'0rem 1.75rem',width:'10%'}}>
                                    <Text className="comp-text subtitle-3" style={{color:"rgba(0, 32, 77, 0.6)"}}>Hành động</Text>
                                   </div>
                                   
                              </div>
                              <div className="col" style={{marginTop:'1rem'}}>
                               <ListCardShop />
                              </div>
                        </div>
                        

                    </div>
                    <div className="row" style={{ justifyContent: "space-between",alignItems:'flex-start' }}>
                        <div className="row" style={{ gap: "1rem" ,flexWrap:"wrap",justifyContent:'start'}}>
                            {CartShop()}
                            {Promotional()}
                        </div>
                        <div className="col" style={{ gap: "1rem",justifyContent:"center",}}>
                            <div className="row" style={{ justifyContent: "space-between", gap: '1rem',width:'100%', }}>   
                                    {UpdateCart()}
                                    {Discount()}
                                 
                            </div>
                            <div className="col" style={{justifyContent:'center'}}>
                                {Oder()}
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                </div>

            </div>

        </>
    )


}
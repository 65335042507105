import { NavLink } from "react-router-dom"
import { Checkbox, ProgressBar, RadioButton, Select1, Text, TextArea, TextField } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown, faChevronRight, faChevronUp, faSearch } from "@fortawesome/free-solid-svg-icons";
import Details from "./Details";
import './../../header.css';
import { useEffect, useRef, useState } from "react";
import { TopicController } from "../../../../module/topic/controller";






export default function PayMentDetail() {


    const Coupon = () => {
        return (
            <>
                <TextField
                    className='placeholder-2'
                    placeholder='Tìm hoặc nhập mã phiếu giảm giá'
                    style={{ height: '4rem', backgroundColor: '#FFF', border: "1px solid rgba(0, 32, 77, 0.4)", borderRadius: "1rem", flex: "1 0 0%" }}
                />
            </>
        )
    }

    const ApplyCode = () => {
        return (
            <>
                <NavLink className="row" style={{ justifyContent: "center", backgroundColor: "#366AE2", borderRadius: "5rem", padding: "0rem 1rem", height: '4rem', }}>
                    <Text className="button-text-3" style={{ pading: '0rem 1.5rem', color: "#FFF" }}>Áp Dụng Mã</Text>
                </NavLink>
            </>
        )
    }
    const Conditions = () => {

        return (
            <>
                <div className="col" style={{ alignItems: "flex-start", justifyContent: "center", padding: "1.75rem 2.75rem", gap: "1rem", borderRadius: "1rem", backgroundColor: "#FCEEED" }}>
                    <div className="row" style={{ gap: '0.25rem' }}>
                        <Text className="heading-8" style={{ color: "rgba(0, 0, 0, 1)" }}>Thêm </Text>
                        <Text className="heading-8" style={{ color: "rgba(225, 67, 55, 1)" }}>154.000</Text>
                        <Text className="heading-8" style={{ color: "rgba(0, 0, 0, 1)" }}>vào giỏ hàng để được giảm 50.000</Text>
                    </div>
                    <ProgressBar title style={{ padding: "0rem" }} fullColor="#FFF" percentColor="#0968D3" percent={50}></ProgressBar>
                </div>
            </>
        )
    }
    const NumberPhone = () => {



        return (
            <>
                <div className="col" style={{ alignItems: 'flex-start', flex: "1", gap: '1rem' }}>
                    <div className="row" style={{ gap: '0.25rem' }} >
                        <Text className="medium1" style={{ color: 'rgba(0, 32, 77, 0.9)', }}>Số Điện thoại</Text>
                        <Text style={{ color: 'rgba(225, 67, 55, 1)' }}>*</Text>
                    </div>
                    <div className="row" style={{ gap: '0.5rem', borderRadius: "1rem", padding: '1.2rem 1.4rem', border: "1px solid rgba(0, 53, 128, 0.20)", width: "100%" }}>
                        <div className="row" style={{ gap: "0.25rem" }}>
                            <Text className="regular-2">VN</Text>
                            <FontAwesomeIcon icon={faChevronDown} style={{ width: "1.25rem", height: '1.25rem', color: 'rgba(0, 32, 77, 0.6)' }} />
                        </div>
                        <TextField placeholder="+84 (555) 000-0000" style={{ padding: "0px", border: "none" }}></TextField>
                    </div>
                </div>


            </>
        )
    }
    const City = () => {
        const [city, setCity] = useState(null);

        const [data, setData] = useState([]);

        useEffect(() => {
            TopicController.getListSimple({ page: 1, take: 8 }).then(res => {
                if (res) setData(res.data)
            })
        }, [])
        console.log(data);

        return (
            <>
                <div className="col" style={{ alignItems: 'flex-start', gap: '1rem', backgroundColor: "#FFF", flex: "1", }}>
                    <Text className="medium1">Thành Phố</Text>
                    <Select1 placeholder="thành phố" options={data} value={city}></Select1>
                </div>
            </>
        )
    }

    const District = () => {

        const [distrist, setDistrict] = useState(null);


        const [data, setData] = useState([]);

        useEffect(() => {
            TopicController.getListSimple({ page: 1, take: 8 }).then(res => {
                if (res) setData(res.data)
            })
        }, [])

        return (
            <>
                <div className="col" style={{ alignItems: 'flex-start', gap: '1rem', backgroundColor: "#FFF", flex: "1", }}>
                    <Text className="medium1">Quận</Text>
                    <Select1 placeholder="Chọn Quận" options={data} value={distrist}></Select1>

                </div>
            </>
        )
    }

    const Ward = () => {

        const [ward, setWard] = useState(null);
        const [data, setData] = useState([]);
        useEffect(() => {
            TopicController.getListSimple({ page: 1, take: 8 }).then(res => {
                if (res) setData(res.data)
            })
        }, [])

        return (
            <>
                <div className="col" style={{ alignItems: 'flex-start', gap: '1rem', backgroundColor: "#FFF", flex: "1 0 0%" }}>
                    <Text className="medium1">Phường</Text>
                    <Select1 placeholder="Chọn Phường" options={data} value={ward}></Select1>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="main-layout-body col">
                <div className="col" style={{ flex: '1 0 0%', height: '100%', overflow: 'hidden auto' }}>
                    <div className="row" style={{ width: '100%', justifyContent: "center" }}>
                        <div className="col col24 col16-xxl col16-xl col18-lg col20-md" style={{ padding: "2rem 3.2rem", gap: '3rem', '--gutter': "0px" }}>
                            <div className="row" style={{ gap: '1rem' }}>
                                <NavLink to="/">
                                    <div className="row" style={{ gap: '1rem' }}>
                                        <Text className="button-text-6 back-cours" >Trang chủ</Text>
                                        <FontAwesomeIcon icon={faChevronRight} style={{ width: "1.2rem", height: '1.2rem', color: "rgba(0, 32, 77, 0.6)" }}></FontAwesomeIcon>
                                    </div>
                                </NavLink>
                                <NavLink to="/CartShopping">
                                    <div className="row" style={{ gap: '1rem' }}>
                                        <Text className="button-text-6 back-cours">Giỏ Hàng</Text>
                                        <FontAwesomeIcon icon={faChevronRight} style={{ width: "1.2rem", height: '1.2rem', color: "rgba(0, 32, 77, 0.6)" }}></FontAwesomeIcon>
                                    </div></NavLink>
                                <Text className="button-text-6" style={{ color: 'rgba(54, 106, 226, 1)' }}>Thanh toán đơn hàng</Text>
                            </div>
                            <div className="row" style={{ justifyContent: 'space-between', alignItems: "flex-start", gap: '15rem' }}>
                                <div className="col" style={{ gap: '5.5rem',flex:'1', }}>
                                    <div className="col" style={{ gap: "2.75rem" }}>

                                        <Text className="heading-4" style={{ color: "rgba(38, 38, 38, 1)" }}>Chi Tiết Thanh Toán</Text>

                                        <div className="col" style={{ gap: "1.75rem" }}>
                                            <div className="row" style={{ gap: '1.25rem' }}>
                                                {Coupon()}
                                                {ApplyCode()}
                                            </div>
                                            {Conditions()}
                                        </div>
                                    </div>
                                    <div className="col" style={{ gap: "5rem" }}>
                                        <div className="col" style={{ gap: "2.5rem" }} >
                                            <Text className="heading-5" style={{ color: "rgba(0, 32, 77, 1)" }}>Thông tin người mua</Text>

                                            <div className="row" style={{ backgroundColor: "#F2F5F8", padding: "1.25rem 1.4rem", borderRadius: "1rem", gap: '0.25rem' }}>
                                                <Text className="label-1" style={{ color: "rgba(0, 32, 77, 0.9)" }}>Bạn Đã có tài khoản?</Text>
                                                <Text className="label-1" style={{ color: "rgba(54, 106, 226, 1)" }}>Ấn vào để đăng nhập</Text>
                                            </div>
                                            <div className="col" style={{ gap: "2.75rem" }}>
                                                <div className="row" style={{ justifyContent: "center", alignItems: "center", gap: "2.75rem" }}>
                                                    <div className="col" style={{ gap: "1rem", alignItems: "flex-start", flex: "1", }}>
                                                        <div className="row" style={{ gap: '0.25rem' }} >
                                                            <Text className="medium1" style={{ color: 'rgba(0, 32, 77, 0.9)', }}>Tên</Text>
                                                            <Text style={{ color: 'rgba(225, 67, 55, 1)' }}>*</Text>
                                                        </div>
                                                        <TextField placeholder="Họ và Tên" style={{ borderRadius: "1rem", backgroundColor: '#FFF', border: "1px solid rgba(0, 53, 128, 0.20)", padding: "1.1rem 1.6rem", width: '100%' }}></TextField>
                                                    </div>
                                                    <div className="col" style={{ gap: "1rem", alignItems: "flex-start", flex: "1" }}>
                                                        <div className="row" style={{ gap: '0.25rem' }} >
                                                            <Text className="medium1" style={{ color: 'rgba(0, 32, 77, 0.9)', }}>Họ</Text>
                                                            <Text style={{ color: 'rgba(225, 67, 55, 1)' }}>*</Text>
                                                        </div>
                                                        <TextField placeholder="Nhập Họ" style={{ borderRadius: "1rem", backgroundColor: '#FFF', border: "1px solid rgba(0, 53, 128, 0.20)", padding: "1.1rem 1.6rem", width: '100%' }}></TextField>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: "center", alignItems: "center", gap: "2.75rem" }}>
                                                    <div className="col" style={{ gap: "1rem", alignItems: "flex-start", flex: "1" }}>
                                                        <div className="row" style={{ gap: '0.25rem' }} >
                                                            <Text className="medium1" style={{ color: 'rgba(0, 32, 77, 0.9)', }}>Email</Text>
                                                            <Text style={{ color: 'rgba(225, 67, 55, 1)' }}>*</Text>
                                                        </div>
                                                        <TextField placeholder="Nhập Email" style={{ borderRadius: "1rem", backgroundColor: '#FFF', border: "1px solid rgba(0, 53, 128, 0.20)", padding: "1.1rem 1.6rem", width: "100%" }}></TextField>
                                                    </div>
                                                    {NumberPhone()}
                                                </div>
                                                <div className="col" style={{ gap: "1rem", alignItems: "flex-start", width: "100%" }}>
                                                    <div className="row" style={{ gap: '0.25rem', }} >
                                                        <Text className="medium1" style={{ color: 'rgba(0, 32, 77, 0.9)', }}>Đia chỉ </Text>
                                                        <Text style={{ color: 'rgba(225, 67, 55, 1)' }}>*</Text>
                                                    </div>
                                                    <TextField placeholder="NHập Địa Chỉ" style={{ borderRadius: "1rem", width: "100%", backgroundColor: '#FFF', border: "1px solid rgba(0, 53, 128, 0.20)", flex: "1 0 0%" }}></TextField>
                                                </div>
                                                <div className="row" style={{ justifyContent: "center", alignItems: "center", gap: "2.75rem", }}>
                                                    {City()}
                                                    {District()}
                                                </div>
                                                <div className="row" style={{ justifyContent: "center", alignItems: "center", gap: "2.75rem", }}>
                                                    {Ward()}
                                                    <div className="col" style={{ gap: "1rem", alignItems: "flex-start", flex: "1 0 0%" }}>
                                                        <Text className="medium1" style={{ color: 'rgba(0, 32, 77, 0.9)', }}>Zip Code</Text>
                                                        <TextField placeholder="Nhập Mã Zip Code" style={{ borderRadius: "1rem", backgroundColor: '#FFF', border: "1px solid rgba(0, 53, 128, 0.20)", padding: "1.2rem 1.4rem", width: "100%" }}></TextField>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ gap: '1.25rem' }}>
                                                    <Checkbox size={17} />
                                                    <Text className="regular1" >Tạo một tài khoản với các thông tin trên</Text>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col" style={{ gap: "2.5rem" }}>
                                            <div>
                                                <Text className="heading-5" style={{ color: "rgba(0, 32, 77, 1)" }}>Thông tin người nhận hàng</Text>
                                            </div>
                                            <div className="col" style={{ gap: '2.5rem' }}>
                                                <div className="row" style={{ gap: '0.75rem', }}>
                                                    <div className="row" style={{ gap: "0.5rem", flex: "1 0 0%",alignItems:"flex-start"}}>
                                                        <RadioButton size={18} id="select" />
                                                        <Text className="label-4"  style={{width:'100%',maxLines:"3"}}>
                                                            Giống thông tin người mua hàng
                                                        </Text>
                                                    </div>
                                                    <div className="row" style={{ gap: "0.5rem", flex: "1 0 0%",alignItems:"flex-start" }}>
                                                        <RadioButton size={18} id="select" />
                                                        <Text className="label-4" style={{width:'100%',maxLines:"3"}}>
                                                            Giống thông tin người mua hàng
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div className="col" style={{ gap: "1rem" }}>
                                                    <Text className="label-3">Ghi chú đơn hàng (không bắt buộc)</Text>
                                                    <TextArea placeholder="Để lại ghi chú cho chúng tôi..." style={{ height: "15rem", borderRadius: "1rem", border: "1px solid rgba(0, 53, 128, 0.20)", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}></TextArea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col" style={{flex:'1'}}>
                                    <Details></Details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
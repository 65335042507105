import { useEffect, useState } from "react";
import { TopicController } from "../../../topic/controller";
import { Checkbox, Text } from "wini-web-components";
import { FilledTrashCan } from "../../../../../assets/const/icon";








export default function ListCardShop() {

  const [data, setData] = useState([]);

  useEffect(() => {
    TopicController.getListSimple({ page: 1, take: 8 }).then(res => {
      if (res) setData(res.data)
    })
  }, [])

  const handleDelete = (id) => {
    
    const updatedItems = data.filter((_, i) => i !== id);
    setData(updatedItems);
  };


  return (
    <div className="col" style={{ height: '44rem', overflowY: "auto" }}>
      {data.map((item, id) => (
        <div key={id} className="row" style={{ borderBottom: "1px solid  rgba(0, 53, 128, 0.08)", alignItems: "center" }}>
          <div className="row" style={{ alignItems: 'center', justifyContent: 'center', padding: '0rem 1.2rem', width: "5%" }}>
            <Checkbox width="1.25rem" height='1.25rem' />
          </div>
          <div className="row" style={{ gap: '1.75rem', alignItems: "center", padding: '2.4rem 1.8rem', width: '35%' }}>
            <img src="https://hoanghamobile.com/tin-tuc/wp-content/uploads/2023/07/anh-phong-canh-dep-24.jpg" style={{ width: "8rem", height: '8rem', borderRadius: '1rem' }} />
            <div className="col" style={{ gap: '1rem', flex: '1' }}>
              <Text className="heading-7 " style={{ color: 'rgba(0, 32, 77, 1)', wordBreak: "break-word", overflow: 'hidden', whiteSpace: "normal", width: "100%" }}>{item.name}</Text>
              <Text className="comp-text subtitle-3">By spagreen</Text>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "flex-end", padding: "0rem 1.75rem", width: '12.5%' }}>
            <Text className=" body-2" style={{ color: "rgba(0, 32, 77, 0.9)" }}>Khóa Học</Text>
          </div>
          <div className="row" style={{ justifyContent: "flex-end", padding: "0rem 1.75rem", width: '12.5%' }}>
            <Text className=" body-2" style={{ color: "rgba(0, 32, 77, 0.9)" }}>932.000đ</Text>
          </div>
          <div className="row" style={{ justifyContent: "flex-end", padding: "0rem 1.75rem", width: '12.5%' }}>
            <Text className=" body-2" style={{ color: "rgba(0, 32, 77, 0.9)" }}>932.000đ</Text>
          </div>
          <div className="row" style={{ justifyContent: "flex-end", padding: "0rem 1.75rem", width: '12.5%' }}>
            <Text className=" body-2" style={{ color: "rgba(0, 32, 77, 0.9)" }}>932.000đ</Text>
          </div>
          <div className="row" style={{ alignItems: 'center', justifyContent: 'center', padding: '0rem 1.2rem', width: '10%', }} onClick={() => handleDelete(id)} >
            <FilledTrashCan width="2rem" height="2rem" />
          </div>
        </div>
      ))}
    </div>

  )
} 